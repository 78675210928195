import React from "react";
import { TrashIcon } from "../utils/styled-components/icons";
import { Button } from "react-bootstrap";

export default function ButtonDelete(props) {
  return (
    <Button
      type="button"
      size="sm"
      variant=""
      className="btn-social --delete"
      onClick={(event) => props.displayDeleteSwall(event)}
    >
      <TrashIcon title="Eliminar" />
    </Button>
  );
}
