import React from "react";
import {TEXTADVSEC, TEXTADV } from "./SignInOutStyles";
import { Nav } from "react-bootstrap";

export default function Ads() {
  return (
    <>
      <a
        href="https://music915.com.ar/"
        className="d-block mb-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt=""
          className="w-100"
          style={{ borderRadius: "5px" }}
          src="img/logoRadio1080x1080.png"
        />
      </a>
      <Nav.Link
          className=""
          target="_blank"
          href="https://wa.me/5493765050405"
      >
        <img
          alt=""
          className="w-100"
          style={{ borderRadius: "5px" }}
          src="img/naranjosJpeg.jpg"
        />
      </Nav.Link>
      <div className="m-3 p-3">
      <TEXTADV> Publica en </TEXTADV>
      <TEXTADVSEC> AutosMisiones.com </TEXTADVSEC>
      </div>
               
  
    </>
  );
}
