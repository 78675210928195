import React, { useState } from "react";
const ReloadContext = React.createContext({});

export function ReloadContextProvider({ children }) {
  const [reload, setReload] = useState(false);
  return (
    <ReloadContext.Provider value={{ reload, setReload }}>
      {children}
    </ReloadContext.Provider>
  );
}

export default ReloadContext;
