import React from "react";
import { Nav } from "react-bootstrap";
import { PrevIcon } from "utils/styled-components/icons";
import { TEXTMARKET } from "./Publication/styles";

export default function GoBackComponent(){

    return(

        <Nav.Link className="d-flex align-items-center justify-content-end" href="/dashboard">
            <PrevIcon
                size={25}
                color="green"
            />
            <TEXTMARKET className="m-0 m-3">Regresar</TEXTMARKET>
        </Nav.Link>

    );

}