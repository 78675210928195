import React from "react";
import { copyToClipboard } from "utils/helps";
import { ShareIcon } from "../utils/styled-components/icons";

export default function ButtonShare({ url })
{
  const textToSend = `*Hola!*, quiero que mires este vehiculo\n*url:* autosmisiones.com${url}`

  const copy = async () =>
  {
    await copyToClipboard(textToSend)
    alert('Enlace copiado!')
  }

  return (
    <button
      className="btn-social --share"
      onClick={copy}
      title="share"
    >
      <ShareIcon style={{ fontSize: '16px' }} />
    </button>
  );
}
//  