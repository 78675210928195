import React, { useState, useEffect, useContext } from "react";
import Skeleton from "react-loading-skeleton";

import InputSearch from "components/InputSearch";
import AdvertisingHome from "components/AdvertisingHome";
import useWindowDimensions from "hooks/useWindowDimensions";
import { getUsDolarCurrency } from "services/externalServices";

import PublicationCard from "./../PublicationCard";
import { getPublicationHome } from "../../../services/publication";
import PublicationsFilter from "components/Publication/Publications/filter";
import Context from "../../../context/OrderByContext";
import FilterByContext from "../../../context/FilterByContext";
import ReloadContext from "../../../context/ReloadContext";
import Pagination from "./Pagination";
import { TEXTALERT } from "../styles";
import { transformPublicationResponse } from "../../../utils";

import "react-loading-skeleton/dist/skeleton.css";
import { getLocalStorage } from "utils/localStorage";

export default function PublicationsComponent() {
  const { width } = useWindowDimensions();
  const [publicationList, setPublicationList] = useState([]);
  const [dolarValue, setDolarValue] = useState("");
  const { orderBy } = useContext(Context);
  const { filterBy } = useContext(FilterByContext);
  const { reload } = useContext(ReloadContext);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPublication, setTotalPublication] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [time, setTime] = useState(null);
  const [fromSearch, setFs] = useState("");
  const publicationPerPage = 20;
  const personId = getLocalStorage("userId");

  useEffect(() => {
    getUsDolar();
    getPublications();
  }, [filterBy, orderBy, reload]);

  async function getUsDolar() {
    const result = await getUsDolarCurrency();
    setDolarValue((dolarValue) => {
      return {
        ...dolarValue,
        venta: result.data?.blue.value_sell,
      };
    });
  }

  async function getPublications(page, title = null) {
    setLoading((prev) => true);
    let isFilterEmpty = Object.values(filterBy).every((item) => item === null);
    const skip = ((!page ? currentPage : page) - 1) * publicationPerPage;
    const queries = { orderBy, filterBy, skip };
    if (dolarValue) queries.dolarValue = dolarValue.venta;
    if (title) queries.title = title;
    const response = await getPublicationHome(queries);
    if (response?.status === 200) {
      const objectCars = transformPublicationResponse(response.data.response);
      setTotalPublication(objectCars[0]?.full_count);
      setPublicationList(objectCars);
      setCurrentPage(!isFilterEmpty && !page ? 1 : page ?? currentPage);
      setLoading((prev) => false);
    }
  }

  const handlePagination = async (page) => {
    if (page === currentPage) return;
    if (fromSearch) {
      alert(page);
      set(fromSearch, page);
    } else {
      await getPublications(page);
      window.scrollTo(0, 0);
    }
  };

  const set = async (val, page) => {
    if (val === "") {
      setLoading(true);
      setFs(false);
      await setCurrentPage((prev) => 1);
      await getPublications(1);
      setLoading(false);
      setIsTyping(false);
      return;
    } else {
      setFs((prev) => val);
      await setCurrentPage((prev) => page ?? 1);
      await getPublications(page, val);
      setIsTyping((prev) => false);
    }
  };

  const handleSearch = (val) => {
    setIsTyping((prev) => true);
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        set(val, null);
      }, 500)
    );
  };

  return (
    <div className="w-100">
      <div className="filter-search-order">
        <PublicationsFilter />
        <InputSearch
          name="seachPublications"
          handleSearch={(e) => handleSearch(e.target.value)}
          placeholder="Buscar publicaciones"
          isTyping={isTyping}
          classes="shadow-sm"
        />
      </div>
      {loading ? (
        <Skeleton
          containerClassName="skeleton-home mb-3"
          height={250}
          width={"100%"}
          count={12}
        />
      ) : publicationList.length <= 0 ? (
        <TEXTALERT>No hay publicaciones</TEXTALERT>
      ) : (
        <>
          <div className="wrapper-card-publications mb-3">
            {publicationList.map((publication, index) => (
              <>
                <PublicationCard
                  userPublication={personId}
                  data={publication}
                  key={index}
                  setPublicationList={setPublicationList}
                  carousel={false}
                  disabledStatusButton={false}
                  editButton={true}
                  style={{ animationDelay: `${(index + 1) * 0.065}s` }}
                />
                {((index + 1) % 5 === 0 || publicationList.length === 4) &&
                  width < 700 && (
                    <div className="w-100 my-2">
                      <AdvertisingHome ad={index % 4} />
                    </div>
                  )}
              </>
            ))}
          </div>
        </>
      )}
      <Pagination
        publicationPerPage={publicationPerPage}
        totalPublication={totalPublication}
        paginate={handlePagination}
        currentPage={currentPage}
      />
    </div>
  );
}
