import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  InputGroup,
  FormControl,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";

import { resetPassword } from "../services/userAccount";
import resetPasswordImage from "../assets/images/reset-password.svg";

export default function ResetPasswordPage() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Get access token from url
  const urlParams = new URLSearchParams(window.location.search);
  const access_token = urlParams.get("access_token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await resetPassword({ password, access_token });
      setSuccess(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (password !== passwordConfirm) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  }, [password, passwordConfirm]);

  // Return to login page
  const handleReturn = () => {
    window.location.href = "/signin";
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h1>Aqui puedes cambiar tu contraseña</h1>
          <form onSubmit={handleSubmit}>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Contraseña"
                aria-label="Contraseña"
                aria-describedby="basic-addon2"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                required
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Confirmar contraseña"
                aria-label="Confirmar contraseña"
                aria-describedby="basic-addon2"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                type="password"
                required
              />
            </InputGroup>
            <InputGroup>
              <Button
                variant="outline-secondary"
                type="submit"
                disabled={passwordMatchError}
              >
                Enviar
              </Button>
            </InputGroup>
          </form>
          <div className="text-center">
            <img
              src={resetPasswordImage}
              className="img-fluid w-25"
              alt="mail box"
            />
          </div>
          {passwordMatchError && (
            <Alert className="mt-4" variant="danger">
              Las contraseñas no coinciden
            </Alert>
          )}
          {error && (
            <Alert className="mt-4" variant="danger">
              Ha ocurrido un error, vuelva a intentarlo
            </Alert>
          )}
          {loading && (
            <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
            </Spinner>
          )}
          {success && (
            <>
              <Alert className="mt-4" variant="success">
                Se ha cambiado tu contraseña, ahora puedes iniciar sesión
              </Alert>
              <div className="mt-4">
                <Button
                  variant="outline-secondary"
                  onClick={() => handleReturn()}
                >
                  Iniciar de sesión
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
