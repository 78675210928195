import React, { useEffect, useState, useRef } from "react";
import { Tab, Tabs, Container, Form } from "react-bootstrap";
import {useLocation, useRoute } from "wouter";
import swal from "sweetalert";

import PrincipalTab from "../components/Publication/PrincipalTab";
import DetailsTab from "../components/Publication/DetailsTab";
import MultimediaTab from "../components/Publication/MultimediaTab";
import NavBar from "../layouts/NavBar";
import useUser from "../hooks/useUser";
import { getProvinces, getCitiesByProvinceId } from "../services/zone";
import {
  postPublication,
  postPublicationMultimedia,
  getPublicationId,
  putPublication,
  deletePublicationMedia,
  getPublicationCountUser,
} from "../services/publication";
import { getPerson } from "../services/userAccount";
import { getLocalStorage } from "../utils/localStorage";
import {
  getCategories,
  getMakesByCategoryId,
  getModelsByMakeIdAndCategoryId,
  getVersionsByModelId,
} from "../services/vehicleData";
import {
  maxSelectFile,
  checkMimeType,
  maxFileSize,
} from "../utils/publicMethods";
import { FormDataInitialState } from "./helpers/PublicationPage.helper";

export default function VehiclePublicationPage() {
  const [, setLocation] = useLocation();
  const [key, setKey] = useState("principal");
  const [, params] = useRoute("/publication/:id");
  const { isLogged } = useUser();

  const [formData, setFormData] = useState(FormDataInitialState);
  const [categories, setCategories] = useState([]);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [customMake, setCustomMake] = useState(null);
  const [customModel, setCustomModel] = useState(null);
  const [customVersion, setCustomVersion] = useState(null);

  const [selectedFile, setSelectedFile] = useState([]);

  const [loading, setLoading] = useState(false);
  const [editBtn, setEditBtn] = useState(params?.id || false);
  const [emailPerson, setEmailPerson] = useState(null);

  const vehicleCategorySelectRef = useRef(null);
  const vehicleMakeSelectRef = useRef(null);
  const vehicleModelSelectRef = useRef(null);
  const vehicleVersionSelectRef = useRef(null);
  const provinceSelectRef = useRef(null);
  const citySelectRef = useRef(null);

  useEffect(() => {
    handleIsLogged();
    async function handleGetProvinces() {
      const result = await getProvinces();
      setProvinces(result.data);
    }

    async function handleGetCategories() {
      const result = await getCategories();
      setCategories(result.data);
    }

    handleGetCategories();
    handleGetProvinces();
    checkForExistingPhone();
    checkNumberOfPost();
  }, []);

  useEffect(() => {
    async function getPublicationEdit() {
      setLoading(true);
      const publication = await getPublicationId(params?.id);

      if (publication.status === 200) {
        setFormData((formData) => {
          return {
            ...formData,
            title: publication.data.response.title,
            km: publication?.data?.response?.km,
            year: publication?.data?.response?.year,
            condition: publication.data.response.condition,
            description: publication.data.response.description,
            createdAt: publication.data.response.createdAt,
            modifiedAt: publication.data.response.modifiedAt,
            vehicleCategoryId: publication?.data?.response?.vehicleCategoryId,
            vehicleCategoryName:
              publication?.data?.response?.vehicleCategory?.name,
            vehicleMakeId:
              publication?.data?.response?.vehiculoVersion?.vehicleModel
                ?.vehicleMake?.id,
            vehicleModelId:
              publication?.data?.response?.vehiculoVersion?.vehicleModel?.id,
            vehiculoVersionId: publication?.data?.response?.vehiculoVersionId,
            customId: publication?.data?.response?.vehicleCustomData?.id,
            vehicleCustomMake:
              publication?.data?.response?.vehicleCustomData?.make,
            vehicleCustomModel:
              publication?.data?.response?.vehicleCustomData?.model,
            vehicleCustomVersion:
              publication?.data?.response?.vehicleCustomData?.version,
            uniqueOwner: publication?.data?.response?.uniqueOwner,
            marketDiscount: publication?.data?.response?.marketDiscount,
            transmision: publication?.data?.response?.transmision,
            engine: publication?.data?.response?.engine,
            fuelType: publication?.data?.response?.fuelType,
            doors: publication?.data?.response?.doors,
            cityId: publication?.data?.response?.cityId,
            province: publication?.data?.response?.city?.province?.id,
            price: publication?.data?.response?.price,
            currencyType: publication?.data?.response?.currencyType,
            ownerPhone: publication?.data?.response?.ownerPhone,
          };
        });
        setEditBtn(true);
        setSelectedFile(() => {
          return publication.data.response.publicationMediaResources.map(
            (file) => {
              return {
                imagePreviewUrl: file.url,
                name: file.url,
                alreadyExists: true,
                delete: false,
              };
            }
          );
        });

        setTimeout(() => {
          shouldCreateEventListener(
            vehicleCategorySelectRef,
            onChangeOptionCategory
          );
          vehicleCategorySelectRef.current.dispatchEvent(new Event("change"));

          shouldCreateEventListener(provinceSelectRef, onChangeOptionProvince);
          provinceSelectRef.current.dispatchEvent(new Event("change"));

          setLoading(false);
        }, 2000);
      }
    }

    if (params?.id) {
      getPublicationEdit();
    }
  }, [params?.id]);

  useEffect(() => {
    async function handleLoading() {
      if (loading && !swal.getState().isOpen) {
        swal({
          title: "Cargando...",
          buttons: false,
        });
      } else if (swal.getState().isOpen) {
        swal.close();
      }
    }
    handleLoading();
  }, [loading]);

  const shouldCreateEventListener = (element, callback, type = "change") => {
    if (element.current.getAttribute("listener") !== "true") {
      element.current.addEventListener(type, callback);
      element.current.setAttribute("listener", "true");
    }
  };

  const handleIsLogged = async () => {
    if (!isLogged){
      swal({
        text: 'Debe registrarse!',
        icon: 'info',
        button:"Ok",
      }).then(() => {
          setLocation(`/signin`);
      });
    }
  }

  // Check for existing phone
  const checkForExistingPhone = async () => {
    const userId = getLocalStorage("userId");


    if(userId){
      
      const response = await getPerson({ id: userId });

      if (response.data?.email === "diegomattos82@gmail.com") {
        setEmailPerson(response?.data?.email);
      }

      // Si no tiene un telefono que salga el modal
      if (response.status === 200 && !response.data?.phones?.length) {
        swal({
          title: "No tienes ningun teléfono de contacto",
          text: "¿Deseas agregar uno? Esto ayudara a que un posible comprador se comunique contigo.",
          icon: "info",
          buttons: ["Cancelar", "Agregar"],
        }).then((response) => {
          if (response) {
            setLocation(`/dashboard`);
          }
        });
      }
  }
  };

  const checkNumberOfPost = async () => {
    
    if (isLogged){    

      const response = await getPublicationCountUser();
      if (
        response.status === 200 &&
        !response?.data?.response?.allowedToCreateNewPublication &&
        !params?.id
      ) {
        swal({
          title: "Ya agoto el limite de publicaciónes para su usuario (3).",
          text: "Si desea tener un perfil con mas publicaciones, comuniquese con nosotros.",
          icon: "info",
          button: "Regresar",
        }).then((response) => {
          if (response) {
            setLocation(`/`);
          }
        });
      }
    }

  };

  const effectChangeSelect = (event) => {
    let name = event.target.name;

    if (name === "vehicleCategoryId" || name === "vehicleMakeId") {
      // DB DATA
      formData["vehicleMakeId"] = "";
      formData["vehicleMake"] = "";
      formData["vehicleModelId"] = "";
      formData["vehicleModel"] = "";
      formData["vehiculoVersionId"] = "";
      formData["vehicleVersion"] = "";
      // CUSTOM DATA
      formData["vehicleCustomMake"] = "";
      formData["vehicleCustomModel"] = "";
      formData["vehicleCustomVersion"] = "";
    }

    if (name === "vehicleModelId") {
      // DB DATA
      formData["vehiculoVersionId"] = "";
      formData["vehicleVersion"] = "";
      // CUSTOM DATA
      formData["vehicleCustomModel"] = "";
      formData["vehicleCustomVersion"] = "";
    }

    if (name === "vehiculoVersionId") {
      // CUSTOM DATA
      formData["vehiculoVersionId"] = "";
      formData["vehicleVersion"] = "";
      formData["vehicleCustomVersion"] = "";
    }
  };

  const onChangeOptionProvince = async (event = provinceSelectRef) => {
    const val = event.target.value;
    const result = await getCitiesByProvinceId({
      provinceId: val,
    });
    if (result?.data) {
      setCities(result.data);
    }

    onChangeInput(event);

    if (provinceSelectRef.current !== "" && params?.id) {
      citySelectRef.current.addEventListener("change", onChangeOptionCity);
      citySelectRef.current.dispatchEvent(new Event("change"));
    }
  };

  const onChangeOptionCity = async (event = citySelectRef) => {
    onChangeInput(event);
  };

  const onChangeOptionCategory = async (event = vehicleCategorySelectRef) => {
    await effectChangeSelect(event);

    if (["3"].includes(event.target.value)) {
      setCustomMake("");
      setCustomModel("");
      setCustomVersion("");
      setMakes([]);
      setModels([]);
      setVersions([]);
    } else {
      setCustomMake(null);
      setCustomModel(null);
      setCustomVersion(null);

      const result = await getMakesByCategoryId({
        vehicleCategoryId: event.target.value,
      });

      if (result?.data?.response) {
        setMakes(result.data.response);
      }
    }

    if (vehicleMakeSelectRef.current !== "" && params?.id) {
      shouldCreateEventListener(vehicleMakeSelectRef, onChangeOptionMake);
      vehicleMakeSelectRef.current.dispatchEvent(new Event("change"));
    }

    onChangeInput(event);
  };

  const onChangeOptionMake = async (event = vehicleMakeSelectRef) => {
    const val = event.target.value;

    if (params?.id) {
      if (val === "otro" || val === "" || val === "Marca") {
        setCustomMake("");
        setCustomModel("");
        setCustomVersion("");

        setFormData((formData) => {
          return { ...formData, vehicleMakeId: "" };
        });
        setFormData((formData) => {
          return { ...formData, vehicleModelId: "" };
        });
        setFormData((formData) => {
          return { ...formData, vehiculoVersionId: "" };
        });
      } else {
        setFormData((formData) => {
          return { ...formData, vehicleCustomMake: "" };
        });
        setFormData((formData) => {
          return { ...formData, vehicleCustomModel: "" };
        });
        setFormData((formData) => {
          return { ...formData, vehicleCustomVersion: "" };
        });

        setCustomMake(null);

        const result = await getModelsByMakeIdAndCategoryId({
          vehicleMakeId: val,
          vehicleCategoryId: vehicleCategorySelectRef.current.value,
        });

        if (result?.data) {
          setModels(result.data);
        }

        if (vehicleModelSelectRef.current !== "") {
          shouldCreateEventListener(vehicleModelSelectRef, onChangeOptionModel);
          vehicleModelSelectRef.current.dispatchEvent(new Event("change"));
        }

        const selectedElement =
          event.target.options[event.target.selectedIndex].text;
        setFormData((formData) => {
          return { ...formData, vehicleMake: selectedElement };
        });

        onChangeInput(event);
      }
    } else {
      effectChangeSelect(event);

      if (val === "otro" || val === "Marca") {
        setCustomMake("");
      } else {
        setCustomMake(null);
        const result = await getModelsByMakeIdAndCategoryId({
          vehicleMakeId: val,
          vehicleCategoryId: vehicleCategorySelectRef.current.value,
        });

        if (result?.data) {
          setModels(result.data);
        }

        const selectedElement =
          event.target.options[event.target.selectedIndex].text;
        setFormData((formData) => {
          return { ...formData, vehicleMake: selectedElement };
        });

        onChangeInput(event);
      }
    }
  };

  const onChangeOptionModel = async (event = vehicleModelSelectRef) => {
    const val = event.target.value;
    effectChangeSelect(event);

    if (params?.id) {
      if (val === "otro" || val === "" || val === "Modelo") {
        setCustomModel("");
        setCustomVersion("");

        setFormData((formData) => {
          return { ...formData, vehicleModelId: "" };
        });
        setFormData((formData) => {
          return { ...formData, vehiculoVersionId: "" };
        });
      } else {
        setCustomModel(null);
        const result = await getVersionsByModelId({
          vehicleModelId: val,
        });

        if (result?.data) {
          setVersions(result.data);
        }

        if (vehicleVersionSelectRef.current !== "") {
          shouldCreateEventListener(
            vehicleVersionSelectRef,
            onChangeOptionVersion
          );
          vehicleVersionSelectRef.current.dispatchEvent(new Event("change"));
        }

        const selectedElement =
          event.target.options[event.target.selectedIndex].text;
        setFormData((formData) => {
          return { ...formData, vehicleModel: selectedElement };
        });

        onChangeInput(event);
      }
    } else {
      effectChangeSelect(event);

      if (val == "otro" || val === "Modelo") {
        setCustomModel("");
      } else {
        setCustomModel(null);
        const result = await getVersionsByModelId({
          vehicleModelId: val,
        });
        if (result?.data) {
          setVersions(result.data);
        }

        const selectedElement =
          event.target.options[event.target.selectedIndex].text;
        setFormData((formData) => {
          return { ...formData, vehicleModel: selectedElement };
        });

        onChangeInput(event);
      }
    }
  };

  const onChangeOptionVersion = (event = vehicleVersionSelectRef) => {
    const val = event.target.value;
    effectChangeSelect(event);

    if (val === "otro" || val === "Version") {
      setCustomVersion("");
    } else {
      setCustomVersion(null);
      setFormData((formData) => {
        return { ...formData, vehiculoVersionId: val };
      });
    }

    const selectedElement =
      event.target.options[event.target.selectedIndex].text;
    setFormData((formData) => {
      return { ...formData, vehicleVersion: selectedElement };
    });

    if (val !== "otro" || val !== "") {
      onChangeInput(event);
    }
  };

  const checkboxValue = (event) => {
    return event.target.checked;
  };

  const onChangeInput = (event) => {
    let val;
    if (event.target.type === "text") {
      val = event.target.value.replace(/\./g, "");
    }

    if (event.target.type === "checkbox") {
      val = checkboxValue(event);
    } else {
      val = event.target.value;
    }
    setFormData((formData) => {
      return { ...formData, [event.target.name]: val };
    });
  };

  const onChangeTab = (keyTab) => {
    const formElement = document.getElementById("publicationForm");
    if (!formElement.checkValidity()) {
      formElement.reportValidity();
    } else {
      setKey(keyTab);
    }
    if (keyTab === "multimedia") checkVehicleCustomData();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    checkVehicleCustomData();

    if (editBtn) {
      handleEdit();
    } else {
      handleSave();
    }
  };

  async function handleEdit() {
    setLoading(true);
    let response = await putPublication(params?.id, formData);
    let successTitle = "Vehículo editado exitosamente";

    await displaySwall(response, successTitle);
    setLoading(false);
  }

  async function handleSave() {
    setLoading(true);
    let response = await postPublication(formData);
    let successTitle = "Vehículo publicado exitosamente";
    await displaySwall(response, successTitle);
    setLoading(false);
  }

  const displaySwall = async (response, successTitle) => {
    setLoading(true);
    if (!response) {
      swal({
        title: "Oops",
        icon: "error",
        text: response?.error?.message || "Error",
      });
    } else {
      await submitImages(response.data.id);
      setLoading(false);
      swal({
        title: successTitle,
        icon: "success",
        buttons: {
          cancel: {
            text: "Cerrar",
            value: false,
            visible: true,
            closeModal: true,
          },
        },
      }).then(() => {
        setLocation(`/vehicle/detail/${response.data.slugUrl}`);
      });
    }
  };

  const deleteFile = (event) => {
    const filePosition = event.currentTarget.value;

    if (selectedFile[filePosition]?.alreadyExists) {
      selectedFile[filePosition].delete = true;
    } else {
      if (Array.isArray(selectedFile)) {
        selectedFile?.splice(filePosition, 1);
      } else {
        // remove only one file
        setSelectedFile([]);
        return;
      }
    }

    setSelectedFile([...selectedFile]);
  };

  //onChangeImage
  const onChangeHandler = (event) => {
    let files = event.target.files;

    const filesFromStatusCount = selectedFile.length;

    const createImgPreviewUrl = (files) => {
      for (let index = 0; index < files.length; index++) {
        files[index].imagePreviewUrl = URL.createObjectURL(files[index]);
      }
    };

    if (
      maxSelectFile(event, filesFromStatusCount) &&
      checkMimeType(event) &&
      maxFileSize(event)
    ) {
      createImgPreviewUrl(files);
      if (selectedFile.length === 0) {
        setSelectedFile(files);
      } else {
        setSelectedFile([...selectedFile, ...files]);
      }
    } else {
      swal({
        title: "Oops, verifica las imágenes",
        icon: "error",
        text: "Verifica que sean de un tipo válido (png, jpeg o jpg), tamaño máximo por imágen (500KB) y cantidad máxima de 5 imágenes.",
      });
    }
  };

  const submitImages = async (publicationId) => {
    const data = new FormData();
    const filesToDelete = [];
    let existSomeFileToUpload = false;
    for (var x = 0; x < selectedFile.length; x++) {
      if (!selectedFile[x]?.alreadyExists) {
        existSomeFileToUpload = true;
        data.append("file", selectedFile[x]);
      } else if (selectedFile[x]?.delete) {
        filesToDelete.push(selectedFile[x].imagePreviewUrl);
      }
    }

    if (existSomeFileToUpload) {
      await postPublicationMultimedia(publicationId, data);
    }

    if (filesToDelete.length > 0) {
      await deletePublicationMedia(publicationId, filesToDelete);
    }

    return true;
  };

  function checkVehicleCustomData() {
    if (
      formData.vehiculoVersionId === "" ||
      formData.vehiculoVersionId === "otro" ||
      formData.vehicleModelId === "otro" ||
      formData.vehicleMakeId === "otro"
    ) {
      setFormData((formData) => {
        return { ...formData, vehiculoVersionId: "" };
      });

      const make = !Boolean(formData["vehicleCustomMake"])
        ? formData["vehicleMake"]
        : formData["vehicleCustomMake"];
      const model = !Boolean(formData["vehicleCustomModel"])
        ? formData["vehicleModel"]
        : formData["vehicleCustomModel"];
      const version = !Boolean(formData["vehicleCustomVersion"])
        ? formData["vehicleVersion"]
        : formData["vehicleCustomVersion"];

      setFormData((formData) => {
        return {
          ...formData,
          vehicleCustomMake: make,
          vehicleCustomModel: model,
          vehicleCustomVersion: version,
        };
      });
    } else {
      setFormData((formData) => {
        return {
          ...formData,
          vehicleCustomMake: "",
          vehicleCustomModel: "",
          vehicleCustomVersion: "",
        };
      });
    }
  }

  return (
    <>
      <NavBar></NavBar>
      <Container className="col-lg-12 m-2 p-2">
        <Form id="publicationForm">
          <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
            <Tab
              eventKey="principal"
              title="Título"
              className="mt-3 p-2"
              disabled
            >
              <PrincipalTab
                nextTab={() => onChangeTab("details")}
                onChangeInput={onChangeInput}
                onChangeOptionCategory={() => onChangeOptionCategory}
                onChangeOptionMake={() => onChangeOptionMake}
                onChangeOptionModel={() => onChangeOptionModel}
                onChangeOptionVersion={() => onChangeOptionVersion}
                categories={categories}
                makes={makes}
                models={models}
                versions={versions}
                customMake={customMake}
                customModel={customModel}
                customVersion={customVersion}
                formData={formData}
                vehicleCategorySelectRef={vehicleCategorySelectRef}
                vehicleMakeSelectRef={vehicleMakeSelectRef}
                vehicleModelSelectRef={vehicleModelSelectRef}
                vehicleVersionSelectRef={vehicleVersionSelectRef}
              />
            </Tab>

            <Tab
              eventKey="details"
              title="Detalles"
              className="mt-3 p-2"
              disabled
            >
              <DetailsTab
                previousTab={() => onChangeTab("principal")}
                nextTab={() => onChangeTab("multimedia")}
                onChangeInput={onChangeInput}
                onChangeOptionProvince={() => onChangeOptionProvince}
                onChangeOptionCity={() => onChangeOptionCity}
                provinces={provinces}
                cities={cities}
                emailPerson={emailPerson}
                formData={formData}
                provinceSelectRef={provinceSelectRef}
                citySelectRef={citySelectRef}
              />
            </Tab>

            <Tab
              eventKey="multimedia"
              title="Imágenes"
              className="mt-3 p-2"
              disabled
            >
              <MultimediaTab
                previousTab={() => onChangeTab("details")}
                onChangeInput={() => onChangeInput}
                handleSubmit={() => handleSubmit}
                onChangeHandler={() => onChangeHandler}
                deleteFile={deleteFile}
                selectedFile={selectedFile}
                editBtn={editBtn}
                loading={loading}
              />
            </Tab>
          </Tabs>
        </Form>
      </Container>
    </>
  );
}
