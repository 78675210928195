import React, { useState } from "react";

import { SortIcon, FilterIcon } from "../../../utils/styled-components/icons";
import ModalComponent from "../../Modal";

export default function FilterCars(props) {
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});

  const modalHandler = (action) => {
    let headingTitle = "";
    switch (action) {
      case "order":
        headingTitle = "Ordenar por";
        break;
      case "filter":
        headingTitle = "Filtrar";
        break;
      default:
        break;
    }
    setModalData({ action, headingTitle });
    setModalShow(true);
  };

  return (
    <>
      <ModalComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        modalDataAction={modalData}
        showFooter={false}
        showFilterByPrice={props?.showFilterByPrice}
        showFilterByModel={props?.showFilterByModel}
        showFilterByVersion={props?.showFilterByVersion}
        showFilterByMarketDiscount={props?.showFilterByMarketDiscount}
        showFilterByKm={props?.showFilterByKm}
      />
      <div className="filter-search-order__buttons d-flex align-items-center justify-content-center">
        <div className="icon_btn" onClick={() => modalHandler("order")}>
          <SortIcon />
        </div>
        <div className="icon_btn" onClick={() => modalHandler("filter")}>
          <FilterIcon />
        </div>
      </div>
    </>
  );
}
