import { useContext, useCallback, useState } from "react";
import Context from "../context/UserContext";
import { signIn } from "../services/userAccount";
import { setLocalStorage, destroyLocalStorage } from "../utils/localStorage";
import { getPerson } from "../services/userAccount";

export default function useUser() {
  const { jwt, setJWT, setUser } = useContext(Context);
  const [state, setState] = useState({
    loading: false,
    error: false,
    errorMessage: null,
  });

  const login = useCallback(
    async ({ email, password }) => {
      try {
        setState({ loading: true, error: false });
        const response = await signIn({ email, password });
        if (response.data?.id) {
          setLocalStorage("token", response.data?.id);
          setLocalStorage("userId", response.data?.userId);
          setLocalStorage("email", response.data?.email);
          setJWT(response.data.id);

          const uData = await getPerson({ id: response.data?.userId });
          setLocalStorage("user", JSON.stringify(uData.data));
          setUser(uData.data);

          setState({ loading: false, error: false, errorMessage: null });
        } else {
          setState({
            loading: false,
            error: true,
            errorMessage: response.error.message,
          });
        }
      } catch (error) {
        setState({ loading: false, error: true, errorMessage: error });
      }
    },
    [setJWT, setUser]
  );

  const logout = useCallback(() => {
    destroyLocalStorage();
    setJWT(null);
    setUser(null);
  }, [setJWT, setUser]);

  return {
    isLogged: Boolean(jwt),
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    loginErrorMessage: state.errorMessage,
    login,
    logout,
  };
}
