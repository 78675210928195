/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "wouter";
import swal from "sweetalert";
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import { BiLoader } from "react-icons/bi";

import Context from "context/UserContext";
import { getPerson } from "services/userAccount";
import { EditIcon } from "utils/styled-components/icons";

import ModalContact from "./ModalContact";
import ButtonWp from "./ButtonWp";
import {
  saveFavorite,
  deleteFavorite,
  deletePublication,
} from "../services/publication";
import useUser from "../hooks/useUser";
import CarouselComponent from "../components/Carousel";
import TableComponent from "../components/TableComponent";
import DescriptionCard from "../components/DescriptionCard";
import ReloadContext from "../context/ReloadContext";
import { getLocalStorage } from "../utils/localStorage";
import { GetMakeModel, GetVersion, getTimeFromNow } from "../utils/publicMethods";
import ButtonShare from "./ButtonShare";
import ButtonDelete from "./ButtonDelete";
import { TEXTMARKETCARD } from "./Publication/styles";
import { STATUS_PUBLICATION } from "utils/constants";

export default function AutoCar({ cardVehicle, loadDetails, ...props }) {
  const { isLogged } = useUser();
  const [isFavorite, setIsFavorite] = useState(
    cardVehicle?.favorites?.length > 0 ?? false
  );
  const { reload, setReload } = useContext(ReloadContext);
  const [slugUrl] = useLocation();
  const [, setLocation] = useLocation();
  const [loadDetailsState, setLoadDetailsState] = useState(loadDetails);
  const [loadStateButton, setLoadStateButton] = useState(false);
  const [userData, setUserData] = useState(false);
  const [, setLoading] = useState(true);
  const { isAdmin } = useContext(Context);

  useEffect(() => {
    setIsFavorite(cardVehicle?.favorites?.length > 0 ?? false);
  }, [cardVehicle]);
  useEffect(() => {
    setLoadDetailsState((prev) => true);
  }, [slugUrl]);
  useEffect(() => {
    setLoadDetailsState((prev) => loadDetails);
  }, [cardVehicle, loadDetails]);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const userId = getLocalStorage("userId");

    if (userId !== null) {
      const uData = await getPerson({ id: userId });
      setUserData(uData.data);
    }
  };

  const handleIsLogged = async (event) => {
    event.preventDefault();
    if (!isLogged) {
      swal({
        text: "Debe registrarse!",
        icon: "warning",
      });
      return;
    }

    setLoadStateButton((prev) => true);

    try {
      if (isFavorite) {
        await deleteFavorite(cardVehicle.id);
      } else {
        await saveFavorite(cardVehicle.id);
      }
      setIsFavorite((prev) => !prev);
      setLoadStateButton((prev) => false);
    } catch (error) {
      console.log(error);
    } finally {
      setReload(!reload);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    await deletePublication(cardVehicle.id);

    swal("Tu publicación ha sido eliminada", {
      icon: "success",
    }).then(() => {
      window.location.href = "/";
    });
    setLoading(false);
  };

  const displayDeleteSwall = (event) => {
    event.preventDefault();

    swal(
      "ATENCION! esta publicación se eliminara definitivamente ¿Esta seguro?",
      {
        icon: "warning",
        buttons: {
          catch: {
            text: "Cancelar",
            value: "catch",
          },
          Eliminar: true,
        },
      }
    ).then((value) => {
      switch (value) {
        case "Eliminar":
          handleDelete();
          break;

        case "catch":
          swal("Acción cancelada!");
          break;

        default:
          break;
      }
    });
  };

  const formatPrice = () => {
    if (cardVehicle?.price) {
      const currencyType = cardVehicle.currencyType || "$";
      const price = cardVehicle?.price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return `${currencyType} ${price}`;
    }
  };

  const getLocationInfo = () => {
    if (cardVehicle?.city?.name || cardVehicle?.city?.province?.name) {
      const location = [
        cardVehicle.city?.name,
        cardVehicle.city?.province?.name,
      ].join(", ");
      return <p className="location mt-3 mb-0">{location}</p>;
    }
  };

  const loggedUserIsOwnerOfThisPublication =
    userData?.id === cardVehicle?.personId;

  return (
    <div className="detail-wrapper">
      {loadDetailsState ? (
        <Skeleton containerClassName="skeleton-detail-page" count={1} />
      ) : (
        <div className="detail-wrapper__slide animated fadeIn">
          <CarouselComponent
            classes={"detail-wrapper__slide__carousel"}
            images={cardVehicle?.publicationMediaResources}
          />
        </div>
      )}
      {loadDetailsState ? (
        <Skeleton containerClassName="skeleton-detail-page__info" count={3} />
      ) : (
        <div className="detail-wrapper__cards-wrapper animated fadeIn">
          <div className="detail-wrapper__cards-wrapper__card">
            <div className="detail-wrapper__cards-wrapper__card__price">
              <span className="mb-0">{formatPrice()}</span>
              <div className="detail-wrapper__cards-wrapper__card__price__favorite">
                {loadStateButton ? (
                  <BiLoader
                    className="custom-card-component__edit-fav__loader"
                    style={{ fontSize: "22px" }}
                  />
                ) : !isFavorite ? (
                  <IoMdHeartEmpty className="icon" onClick={handleIsLogged} />
                ) : (
                  <IoMdHeart
                    className="icon --favorite animated tada"
                    onClick={handleIsLogged}
                  />
                )}
              </div>
            </div>
            <div className="detail-wrapper__cards-wrapper__card__middle">
              <p className="mb-0 make-model">
                {cardVehicle?.year !== 0 && `Año ${cardVehicle?.year}`}
                {cardVehicle?.year !== 0 && cardVehicle?.km !== 0 && " - "}
                {cardVehicle?.km !== 0 &&
                  `${cardVehicle?.km
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${
                    cardVehicle?.vehicleCategoryId === 3 ? "horas" : "km"
                  }`}
              </p>
              {cardVehicle?.marketDiscount ? (
                <TEXTMARKETCARD className="">¡Precio bajo info!</TEXTMARKETCARD>
              ) : (
                ""
              )}
              <p className="mb-0">
                <GetMakeModel data={cardVehicle} /> -{" "}
                <GetVersion data={cardVehicle} />
              </p>
              {getLocationInfo()}
              <p className="text-muted text-right mb-0 fw-400">
                {getTimeFromNow(cardVehicle?.modifiedAt)}
              </p>
              {   cardVehicle.statusId === STATUS_PUBLICATION.CANCELED ? (
                  <p className="text-left mb-0 fw-400 canceled-publication">
                    Publicacion Cancelada!
                  </p> )
                : cardVehicle.statusId === STATUS_PUBLICATION.PAUSE ? (
                  <p className="text-left mb-0 fw-400 paused-publication">
                    Publicacion inactiva!
                  </p> )
                : ""
              }
          <hr className="my-1" />
            </div>
          </div>
          <div className="w-100 detail-wrapper__cards-wrapper__contact-links">
            {((isLogged && !loggedUserIsOwnerOfThisPublication) ||
              !isLogged ||
              isAdmin()) && (
              <ButtonWp
                cellPerson={
                  isLogged && isAdmin() && loggedUserIsOwnerOfThisPublication
                    ? cardVehicle.ownerPhone
                    : cardVehicle?.person?.phones[0]?.phone
                }
                emailPerson={ isAdmin() ? userData.email : null}
                url={slugUrl}
              />
            )}
            {isLogged && (isAdmin() || loggedUserIsOwnerOfThisPublication) && (
              <ButtonDelete displayDeleteSwall={displayDeleteSwall} />
            )}
            <ButtonShare url={slugUrl} />
            {!loggedUserIsOwnerOfThisPublication && (
              <ModalContact
                mailPerson={cardVehicle?.person?.email}
                urlPublication={window.location.href}
                publicationImg={cardVehicle?.publicationMediaResources[0]?.url}
                emailTo={cardVehicle?.person?.email}
              />
            )}
            {loggedUserIsOwnerOfThisPublication && (
              <button
                className="btn-social --edit"
                onClick={(e) => {
                  e.preventDefault();
                  setLocation(`/publication/${cardVehicle.id}`);
                }}
                title="Editar publicación"
              >
                <EditIcon style={{ fontSize: "16px" }} />
              </button>
            )}
          </div>
          {cardVehicle?.description && (
            <div className="detail-wrapper__cards-wrapper__card">
              <h5 className="fw-700 text-left">Descripción</h5>
              <DescriptionCard data={cardVehicle.description} />
            </div>
          )}
          <div className="detail-wrapper__cards-wrapper__card">
            <h5 className="fw-700 text-left">Ficha tecnica</h5>
            <TableComponent className="tableDetail" data={props.showMore} />
          </div>
        </div>
      )}
    </div>
  );
}
