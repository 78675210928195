import { Table } from "react-bootstrap";

export default function TableComponent(props) {
  return (
    <Table striped hover size="sm" className="table-detail-page mb-0">
      <tbody>
        {props.data.map((d) => (
          <tr>
            <th className="fw-600 text-left">{d.key}</th>
            <th className="fw-400">{d.value}</th>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
