import React, { useEffect, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { PaginationBtn, PaginationBtnActive, Item, List } from "../styles";

export default function Pagination(props) {
  let paginate = props.paginate;
  let totalPublication = props.totalPublication;
  let publicationPerPage = props.publicationPerPage;
  const pageNumber = [];
  const [currentPage, setCurrentPage] = useState(props.currentPage);

  useEffect(() => {
    setCurrentPage((prev) => props.currentPage);
  }, [props.currentPage]);

  for (let i = 1; i <= Math.ceil(totalPublication / publicationPerPage); i++) {
    pageNumber.push(i);
  }

  return (
    pageNumber.length > 1 && (
      <div className="divPagination">
        <List horizontal>
          {pageNumber.map((number, index) => (
            <Item key={index}>
              <ButtonGroup>
                {currentPage === number ? (
                  <PaginationBtnActive
                    outline
                    className="m-2"
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </PaginationBtnActive>
                ) : (
                  <PaginationBtn
                    outline
                    className="m-2"
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </PaginationBtn>
                )}
              </ButtonGroup>
            </Item>
          ))}
        </List>
      </div>
    )
  );
}
