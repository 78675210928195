import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Context from "context/UserContext";
import MyProfile from "components/Dashboard/MyProfile";
import MyPublications from "components/Dashboard/MyPublications";
import UserManagement from "components/Dashboard/UserManagement";
import AllPublications from "components/Dashboard/AllPublications";
import UpdatePricePublications from "components/Dashboard/UpdatePricePublications";
import NavBar from "../../layouts/NavBar";
import ImagesConcess from "components/Dashboard/ImagesConcess";

export default function DashboardPage() {
  const { isAdmin, isBussiness } = useContext(Context); 

  return (
    <>
      <NavBar></NavBar>
      <Row className="m-3">
        <Col sm={12} md={6}>
          <Col>
            <MyProfile />
          </Col>

          
          <Col>
            <MyPublications />
          </Col>

          {isAdmin() && (
            <Col>
              <UserManagement />
            </Col>
          )}
        </Col>

        <Col sm={12} md={4}>
          {isAdmin() && (
            <Col>
              <AllPublications />
            </Col>
          )}

          {isAdmin() && (
            <Col>
              <UpdatePricePublications />
            </Col>
          )}

          {isBussiness() && (
            <Col>
              <ImagesConcess />
            </Col>
          )}
        </Col>
      </Row>
    </>
  );
}
