import { getLocalStorage } from "../../utils/localStorage";

export const FormDataInitialState = {
  personId: getLocalStorage("userId"),
  title: "",
  condition: "Usado",
  description: "",
  vehicleCategoryId: "",
  vehicleMakeId: "",
  vehicleModelId: "",
  vehiculoVersionId: "",
  vehicleMake: "",
  vehicleModel: "",
  vehicleVersion: "",
  vehicleCustomMake: "",
  vehicleCustomModel: "",
  vehicleCustomVersion: "",
  km: "",
  year: "",
  uniqueOwner: "",
  transmision: "",
  engine: "",
  fuelType: "",
  doors: "",
  cityId: "",
  province: "",
  statusId: 1,
  price: "",
  currencyType: "ARS$",
  swap: "",
  ownerPhone: "",
  files: [],
};
