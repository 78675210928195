import React from "react";
import { TEXTOPTION, TEXTOPTIONS } from "./SignInOutStyles";
import { TEXTMARKET } from "./Publication/styles";
import { HandIcon } from "utils/styled-components/icons";
import { Tooltip } from "react-bootstrap";
import FooterPage from "./Footer/FooterPage";

export default function Error404ComponentPage() {

    return( 
            <>
                <h1>404 - Recurso no encontrado</h1>
                <TEXTOPTION>Parece que esta publicación ya no se encuentra disponible</TEXTOPTION>
                <TEXTMARKET>Dale click al enlace y segui buscando tu vehiculo con nosotros</TEXTMARKET>
        
                <div className="m-3 p-3 justify-content-center align-items-center contenedor-icono-enlace">
                <HandIcon
                    className="mr-1"
                    style={{ verticalAlign: 'middle' }} // Alineación vertical
                    color="green"
                    size={25} />
                
                <a
                    target=""
                    rel="noreferrer"
                    title="Ir al inicio"
                    href="https://autosmisiones.com/"
                    className="ml-1"
                    style={{ verticalAlign: 'middle'}} // Alineación vertical y sin subrayado
                    >
                    <TEXTOPTIONS>AutosMisiones.com</TEXTOPTIONS>

                        <Tooltip placement="bottom" target="inicio-icono">
                        Ir a inicio
                        </Tooltip>
                </a>
                </div>
                <FooterPage />
        </>

    );
}