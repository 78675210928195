import React, { useEffect, useState } from "react";
import NavBar from "../../layouts/NavBar";
import { Container, Row, Col } from "react-bootstrap";
import { getFavorites } from "../../services/publication";
import PublicationCard from "../../components/Publication/PublicationCard";
import FooterPage from "components/Footer/FooterPage";

export default function FavoritesPage() {
  const [favorites, setUserFavorites] = useState([]);

  useEffect(() => {
    setFavoritesList();
  }, []);

  const setFavoritesList = async () => {
    const favoritesData = await getFavorites();
    setUserFavorites(
      favoritesData.data.map((data) => {
        data.publication.isFavorite = true;
        return data.publication;
      })
    );
  };

  return (
    <>
      <NavBar></NavBar>
      <Container fluid className="">
        <h5>Favoritos</h5>
        <Col md="12" className="p-0">
          <Row className="justify-content-md-center align-items-center">
            {favorites
              .filter((favorite) => favorite.statusId === 1)
              .map((favorite) => (
                <Col md="4" key={`favorite-list-${favorite.id}`}>
                  <PublicationCard
                    data={favorite}
                    carousel={false}
                    editBtn={false}
                    set
                    disabledStatusButton={false}
                    setPublicationList={setFavoritesList}
                  />
                </Col>
              ))}
          </Row>
        </Col>

        <FooterPage />
      </Container>
    </>
  );
}
