import React from 'react';

import ImgItem from '../../ImgItem';
import { Row } from 'react-bootstrap';

const InputFile = ({
    name,
    id,
    onChange,
    selectedFile,
    deleteFile
    }) => {

    return (
        <>
            <input
                type="file"
                name={name}
                id={id}
                onChange={(event) => onChange(event)}
                className="inputfile"
            />
            <label htmlFor={id}>
                Cargar Imagen
            </label>
            <Row className="justify-content-center">
            <ImgItem
                index={selectedFile?.index}
                fileName={selectedFile?.name}
                imagePreviewUrl={selectedFile?.imagePreviewUrl}
                deleteFile={deleteFile}
            />
            </Row> 
        </>
    );
};

export default InputFile;