import styled from "styled-components";

export const TEXTFOOTER = styled.span`
  color: #ffffff;
  font-size: 20px;
`;

export const TEXTCONTACTFB = styled.span`
  color: #ffffff;
  font-size: 12px;
`;

export const TEXTCONTACTWP = styled.span`
  color: #000000;
  font-size: 12px;
`;

export const TEXTOPTION = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  font-family: buttonFont;
`;

export const WEBSRELATION = styled.p`
  font-weight: bold;
  font-size: 30px;
  color: #2d572c;
  font-family: buttonFont;
`;

export const TEXTNEWCONCESS = styled.p`
  font-weight: bold;
  font-size: 30px;
  color: #5f2c1c;
  font-family: verdana;
`;
