import React from "react";
import PublicationCard from "components/Publication/PublicationCard";
import Skeleton from "react-loading-skeleton";

export default function PublicationListComponent({
  title,
  publications,
  setPublicationList,
  alignRight = false,
  editBtn,
  disabledStatusButton,
  load,
  ...rest
}) {
  const publications_ = publications ?? [];
  return (
    (load || publications_.length > 0) && (
      <div className="div-cards-related-component mt-3" {...rest}>
        <p
          className="div-cards-related-component__title text-center"
          right={`${alignRight}`}
        >
          {title ?? "Quizas te puedan interesar"}
        </p>
        <div className="div-cards-related-component__cards">
          {load ? (
            <Skeleton
              count={5}
              width={250}
              containerClassName="d-flex align-items-center justify-content-start"
              className="mr-3"
              height={250}
            />
          ) : (
            publications_?.map((publication, index) => (
              <div className="div-cards-related-component__cards__item">
                <PublicationCard
                  editBtn={false}
                  data={publication}
                  key={index}
                  setPublicationList={setPublicationList}
                  carousel={false}
                />
              </div>
            ))
          )}
        </div>
      </div>
    )
  );
}
