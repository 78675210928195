import {
  FaFacebook,
  FaGoogle,
  FaEnvelope,
  FaKey,
  FaWhatsapp,
  FaMapMarkerAlt,
  FaCalendar,
  FaTint,
  FaTachometerAlt,
  FaHeart,
  FaBell,
  FaExternalLinkAlt,
  FaSort,
  FaSlidersH,
  FaEdit,
  FaTrash,
  FaSearch,
  FaCheck,
  FaThumbsUp,
  FaUser,
  FaPhone,
  FaInstagram,
  FaSave,
  FaShareAlt,
  FaWindowClose,
  FaBitcoin,
  FaDollarSign,
  FaClock,
  FaDirections,
  FaArrowLeft ,
  FaHandPointRight,
} from "react-icons/fa";
import styled from "styled-components";

export const FacebookIcon = styled(FaFacebook)`
  color: #3b5998;
  transform: scale(1.5);
`;
export const GoogleIcon = styled(FaGoogle)`
  color: #d44832;
  transform: scale(1.5);
`;
export const EnvelopeIcon = styled(FaEnvelope)`
  title: ${(props) => props.title ?? ""};
  transform: scale(1);
`;
export const KeyIcon = styled(FaKey)`
  transform: scale(1);
`;
export const WpIcon = styled(FaWhatsapp)`
  transform: scale(1);
`;
export const ShareIcon = styled(FaShareAlt)`
  transform: scale(1);
`;
export const MapIcon = styled(FaMapMarkerAlt)`
  transform: scale(1);
`;
export const CalendarIcon = styled(FaCalendar)`
  transform: scale(1);
`;
export const TintIcon = styled(FaTint)`
  transform: scale(1);
`;
export const TachometIcon = styled(FaTachometerAlt)`
  transform: scale(1);
`;
export const HeartIcon = styled(FaHeart)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.transformscale ?? "1"};);
`;
export const BellIcon = styled(FaBell)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
`;
export const ExternalLinkAltIcon = styled(FaExternalLinkAlt)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
`;
export const SortIcon = styled(FaSort)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
`;
export const FilterIcon = styled(FaSlidersH)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
`;
export const EditIcon = styled(FaEdit)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
`;
export const TrashIcon = styled(FaTrash)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
`;
export const CheckIcon = styled(FaCheck)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
  title: ${(props) => props.title ?? ""};
`;
export const LikeIcon = styled(FaThumbsUp)`
  transform: scale(1.5);
`;
export const UserIcon = styled(FaUser)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
  title: ${(props) => props.title ?? ""};
`;

export const ClockIcon = styled(FaClock)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
  title: ${(props) => props.title ?? ""};
`;

export const DirectionsIcon = styled(FaDirections)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
  title: ${(props) => props.title ?? ""};
`;

export const PhoneIcon = styled(FaPhone)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
  title: ${(props) => props.title ?? ""};
`;

export const InstagramIcon = styled(FaInstagram)`
  transform: scale(1.5);
`;

export const SaveIcon = styled(FaSave)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
`;

export const CancelIcon = styled(FaWindowClose)`
  color: ${(props) => props.color ?? ""};
  transform: scale(${(props) => props.scale ?? "1"});
`;

export const BitcoinIcon = styled(FaBitcoin)`
  transform: scale(1);
`;

export const DolarIcon = styled(FaDollarSign)`
  transform: scale(1);
`;

export const PrevIcon = styled(FaArrowLeft)`
  transform: scale(1);
`;

export const SearchIcon = ({ ...rest }) => {
  return <FaSearch {...rest} />;
};

export const HandIcon = styled(FaHandPointRight)`
transform: scale(1);
`;