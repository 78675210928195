import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  InputGroup,
  FormControl,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";

import { forgetPassword } from "../services/userAccount";
import forgetPasswordImage from "../assets/images/forget-password.svg";

export default function ForgetPasswordPage() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await forgetPassword({ email });
      setSuccess(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  // Return to login page
  const handleReturn = () => {
    window.location.href = "/signin";
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h1>Recuperar contraseña</h1>
          <p>
            Ingresa tu correo electrónico y te enviaremos un enlace para
            recuperar tu contraseña
          </p>
          <form onSubmit={handleSubmit}>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Correo electrónico"
                aria-label="Correo electrónico"
                aria-describedby="basic-addon2"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <InputGroup.Append>
                <Button variant="outline-secondary" type="submit">
                  Enviar
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </form>
          <div className="text-center">
            <img
              src={forgetPasswordImage}
              className="img-fluid w-25"
              alt="mail box"
            />
          </div>
          <div className="mt-4">
            <Button variant="outline-secondary" onClick={() => handleReturn()}>
              Regresar al inicio de sesión
            </Button>
          </div>
          {error && (
            <Alert variant="danger">El correo electrónico no existe</Alert>
          )}
          {loading && (
            <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
            </Spinner>
          )}
          {success && (
            <Alert variant="success">
              Se ha enviado un enlace para recuperar tu contraseña
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
}
