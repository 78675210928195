import React, { useState, useEffect } from "react";
import {
  getCriptoCurrency,
  getUsDolarCurrency,
} from "../services/externalServices";
import { CurrencyFormatter } from "../utils/helps";

export default function PrincipalAdvertisingColumn() {
  const [usData, setUsData] = useState({
    compra: "",
    venta: "",
  });
  const [coin, setCoin] = useState([]);

  useEffect(() => {
    getUsDolar();
    getCripto();
  }, []);

  async function getUsDolar() {
    const result = await getUsDolarCurrency();
    setUsData((usData) => {
      return {
        ...usData,
        compra: JSON.stringify(result.data?.blue.value_buy),
        venta: result.data?.blue.value_sell,
      };
    });
  }

  async function getCripto() {
    const resultBt = await getCriptoCurrency();
    if (resultBt?.status === 200) {
      setCoin(resultBt.data);
    }
  }

  return (
    <div className="btc-wrapper shadow">
      <div className="btc-wrapper__title">US$ - BTC</div>
      <div className="btc-wrapper__values">
        <div className="btc-wrapper__values__in">
          <img
            className="w-24"
            src="img/united-states.svg"
            alt="logo auto misiones"
          />
          <span>Compra: AR${CurrencyFormatter.format(usData.compra)}</span>
        </div>
        <div className="btc-wrapper__values__in">
          <img
            className="img-fluid"
            src="img/united-states.svg"
            alt="U-S-Flag"
          />
          <span>Venta: AR${CurrencyFormatter.format(usData.venta)}</span>
        </div>
        <div className="btc-wrapper__values__in">
          <img
            className="img-fluid rounded-circle"
            src="img/bitcoin.png"
            alt="logo btc"
          />
          {coin ? (
            <span> ${CurrencyFormatter.format(coin[0]?.current_price)} </span>
          ) : (
            <span>No disponible</span>
          )}
        </div>
      </div>
    </div>
  );
}
