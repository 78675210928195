import React from "react";
import {
  Form,
  Col,
  Button,
  ButtonGroup,
  Container,
  InputGroup,
  Row,
} from "react-bootstrap";
import NumberFormat from "react-number-format";
import { TEXTPHONE, TEXTFLAG } from "components/Dashboard/styles";
import { PhoneIcon } from "utils/styled-components/icons";

export default function DetailsTab(props) {
  const doors = [
    { id: 1, cantDoors: 1 },
    { id: 2, cantDoors: 2 },
    { id: 3, cantDoors: 3 },
    { id: 4, cantDoors: 4 },
    { id: 5, cantDoors: 5 },
  ];

  const transmision = [
    { id: 1, name: "Manual" },
    { id: 2, name: "Automatica" },
  ];

  const fuelType = [
    { id: 1, name: "Nafta" },
    { id: 2, name: "Gasoil" },
    { id: 3, name: "Otro" },
  ];

  return (
    <Container>
      <Form.Row>
        {!["3", "4"].includes(props.formData.vehicleCategoryId) && (
          <Col className="m-1 p-1">
            <Form.Group>
              <Form.Control
                onChange={(e) => props.onChangeInput(e)}
                name="transmision"
                as="select"
              >
                {props?.formData?.transmision !== "" ? (
                  <option disabled>Transmisión</option>
                ) : (
                  <option selected disabled>
                    Transmisión
                  </option>
                )}
                {transmision.map((trans) =>
                  props?.formData?.transmision &&
                  props.formData.transmision === trans.name ? (
                    <option key={trans.id} value={trans.name} selected>
                      {trans.name}
                    </option>
                  ) : (
                    <option key={trans.id} value={trans.name}>
                      {trans.name}
                    </option>
                  )
                )}
              </Form.Control>
            </Form.Group>
          </Col>
        )}

        <Col className="m-1 p-1">
          <Form.Control
            onChange={(e) => props.onChangeInput(e)}
            value={props.formData.engine}
            name="engine"
            placeholder="Motor"
          />
        </Col>

        {!["2", "3", "4", "5"].includes(props.formData.vehicleCategoryId) && (
          <Col className="m-1 p-1">
            <Form.Control
              onChange={(e) => props.onChangeInput(e)}
              name="fuelType"
              as="select"
            >
              {props?.formData?.fuelType !== "" ? (
                <option disabled>Combustible</option>
              ) : (
                <option selected disabled>
                  Combustible
                </option>
              )}
              {fuelType.map((fuel) =>
                props?.formData?.fuelType &&
                props.formData.fuelType === fuel.name ? (
                  <option key={fuel.id} value={fuel.name} selected>
                    {fuel.name}
                  </option>
                ) : (
                  <option key={fuel.id} value={fuel.name}>
                    {fuel.name}
                  </option>
                )
              )}
            </Form.Control>
          </Col>
        )}

        {!["2", "3", "4", "5"].includes(props.formData.vehicleCategoryId) && (
          <Col className="m-1 p-1">
            <Form.Control
              onChange={(e) => props.onChangeInput(e)}
              name="doors"
              as="select"
            >
              {props?.formData?.doors !== "" ? (
                <option disabled>Cantidad de puertas</option>
              ) : (
                <option selected disabled>
                  Cantidad de puertas
                </option>
              )}
              {doors.map((door) =>
                props?.formData?.doors &&
                props.formData.doors?.toString() ===
                  door.cantDoors?.toString() ? (
                  <option key={door.id} value={door.id} selected>
                    {door.cantDoors}
                  </option>
                ) : (
                  <option key={door.id} value={door.id}>
                    {door.cantDoors}
                  </option>
                )
              )}
            </Form.Control>
          </Col>
        )}
      </Form.Row>

      <Form.Row>
        <Col className="m-1 p-1">
          <Form.Control
            name="province"
            as="select"
            onChange={props.onChangeOptionProvince(this)}
            ref={props.provinceSelectRef}
          >
            {props?.formData?.province !== "" ? (
              <option disabled>Provincia</option>
            ) : (
              <option selected disabled>
                Provincia
              </option>
            )}
            {props.provinces.map((province) =>
              props?.formData?.province &&
              props.formData.province?.toString() ===
                province.id?.toString() ? (
                <option key={province.id} value={province.id} selected>
                  {province.name}
                </option>
              ) : (
                <option key={province.id} value={province.id}>
                  {province.name}
                </option>
              )
            )}
          </Form.Control>
        </Col>

        <Col className="m-1 p-1">
          <Form.Control
            name="cityId"
            as="select"
            onChange={props.onChangeOptionCity(this)}
            ref={props.citySelectRef}
          >
            {props?.formData?.cityId !== "" ? (
              <option disabled>Ciudad</option>
            ) : (
              <option selected disabled>
                Ciudad
              </option>
            )}
            {props.cities.map((city) =>
              props?.formData?.cityId &&
              props.formData.cityId?.toString() === city.id?.toString() ? (
                <option key={city.id} value={city.id} selected>
                  {city.name}
                </option>
              ) : (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              )
            )}
          </Form.Control>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col className="m-1 p-1" md="3" sm="12">
          <Form.Group className="mt-3">
            <NumberFormat
              value={props.formData?.price}
              placeholder={`${
                props?.formData?.currencyType === "US$" ? "USD$" : "$"
              }`}
              decimalSeparator={","}
              thousandSeparator={"."}
              onValueChange={(v) => {
                const { value } = v;
                props.onChangeInput({
                  target: { value: value, name: "price" },
                });
              }}
            />
            <Form.Check
              className="p-2"
              label="ARS$"
              name="currencyType"
              onChange={(e) => props.onChangeInput(e)}
              title="Peso argentino"
              type="radio"
              id="inline-radio-1"
              value="ARS$"
              checked={
                (props?.formData?.currencyType !== "" &&
                  props?.formData?.currencyType === "ARS$") ||
                props?.formData?.currencyType === ""
                  ? true
                  : ""
              }
            />
            <Form.Check
              className="p-2"
              label="US$"
              title="Dólares americanos"
              name="currencyType"
              onChange={(e) => props.onChangeInput(e)}
              type="radio"
              id="inline-radio-2"
              value="US$"
              checked={
                props?.formData?.currencyType !== "" &&
                props?.formData?.currencyType === "US$"
                  ? true
                  : ""
              }
            />
          </Form.Group>
        </Col>
        <Col className="mt-3" md="3" sm="12">
          <Form.Group>
            <Form.Check
              className=""
              custom
              name="swap"
              label="Acepta permuta"
              type="checkbox"
              checked={
                props?.formData?.swap && props?.formData?.swap ? true : false
              }
              id="custom-inline-4"
              onChange={(e) => props.onChangeInput(e)}
            />
            <br />
            <Form.Check
              custom
              name="marketDiscount"
              label="Precio bajo info"
              type="checkbox"
              checked={
                props?.formData?.marketDiscount &&
                props.formData?.marketDiscount
                  ? true
                  : false
              }
              id="custom-inline-5"
              onChange={(e) => props.onChangeInput(e)}
            />
          </Form.Group>
        </Col>
        {props.emailPerson === "diegomattos82@gmail.com" && (
          <Col className="m-1 p-1">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <PhoneIcon title="Teléfono" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                prefix={"+549"}
                name="ownerPhone"
                placeholder="Contacto dueño"
                type="text"
                value={props.formData.ownerPhone}
                onChange={(e) => props.onChangeInput(e)}
              />
            </InputGroup>

            <Row className="justify-content-center m-1">
              <TEXTPHONE>(cod. pais + cod. área + teléfono)</TEXTPHONE>
            </Row>

            <Row className="justify-content-center m-1">
              ej:
              <img
                className="img-fluid m-1"
                src="img/argentina.svg"
                width="20"
                height="20"
                alt="ARG-Flag"
              />
              <TEXTFLAG>549 3764 123456</TEXTFLAG>
            </Row>

            <Row className="justify-content-center m-1">
              ej:
              <img
                className="img-fluid m-1"
                src="img/paraguay.svg"
                width="20"
                height="20"
                alt="PY-Flag"
              />
              <TEXTFLAG>595 975 123456</TEXTFLAG>
            </Row>
          </Col>
        )}
      </Form.Row>

      <Form.Row>
        <Col>
          <ButtonGroup className="float-right mt-3">
            <Button onClick={props.previousTab} type="button" className="mx-2">
              Atrás
            </Button>
            <Button onClick={props.nextTab} type="button" className="mx-2">
              Siguiente
            </Button>
          </ButtonGroup>
        </Col>
      </Form.Row>
    </Container>
  );
}
