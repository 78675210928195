import React, { useEffect, useRef, useState } from "react";
import NavBar from "../../layouts/NavBar";
import { Container, Table, Button, Form } from "react-bootstrap";
import {
  getAllPerson,
  getRolesUser,
  updateRolePerson,
} from "services/userAccount";
import { CancelIcon, EditIcon, SaveIcon } from "utils/styled-components/icons";
import swal from "sweetalert";
import FooterPage from "components/Footer/FooterPage";
import Skeleton from "react-loading-skeleton";
import GoBackComponent from "components/GoBackComponent";

export default function UsersPage() {
  const [userData, setUserData] = useState([]);
  const [roles, setRoles] = useState([]);
  const editButton = useRef(null);
  const [editBtn, setEditBtn] = useState(null);
  const [loadData, setLoadData] = useState(true);
  const [dataUpdate, setDataUpdate] = useState({
    userId: "",
    userRole: "",
    userRoleId: "",
  });

  useEffect(() => {
    getAllUser();
    handleGetRoles();
  }, []);

  async function handleGetRoles() {
    const roles = await getRolesUser();
    setRoles(roles.data);
  }

  async function getAllUser() {
    setLoadData((prev) => true);
    const people = await getAllPerson();
    setUserData(people.data);
    setLoadData((prev) => false);
  }

  async function showSwall(response, successTitle, iconResponse) {
    if (!response.error) {
      swal({
        title: successTitle,
        icon: iconResponse,
        button: "Ok",
      });
    }
  }

  async function handleEdit() {
    if (
      !dataUpdate.roleId ||
      userData[editBtn].roles[0].roleId === dataUpdate.roleId
    )
      return alert("seleccione un rol diferente al mostrado inicialmente");

    console.log(dataUpdate);
    try {
      const response = await updateRolePerson(dataUpdate);

      showSwall(response, "Datos actualizados correctamente", "success");
    } catch (error) {
      swal({
        title: "Error",
        text: error,
        icon: "error",
        button: "Ok",
      });
    } finally {
      setEditBtn((prev) => null);
      setDataUpdate((prev) => ({
        userId: "",
        roleId: "",
        userRoleId: "",
      }));
    }
  }

  return (
    <>
      <NavBar></NavBar>

      <GoBackComponent/>
      
      <Container>
        <h5 className="my-4">
          {loadData ? "Cargando usuarios..." : "Usuarios"}
        </h5>
        {loadData ? (
          <div>
            {Array.from("123456").map((item, index) => (
              <Skeleton width="100%" height={40} className="mb-1 rounded" />
            ))}
          </div>
        ) : (
          <Table striped bordered responsive hover variant="dark">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Correo</th>
                <th style={{ minWidth: "100px" }}>Rol</th>
              </tr>
            </thead>
            <tbody>
              {userData?.map((user, index) => (
                <tr key={index}>
                  <td>{user.firstName}</td>
                  <td>{user.email}</td>
                  <td>
                    <Form.Control
                      name={`select-roles-${index}`}
                      as="select"
                      disabled={dataUpdate.userId !== user.id}
                      onChange={(e) =>
                        setDataUpdate((prev) => ({
                          ...prev,
                          roleId: parseInt(e.target.value),
                          userRoleId: user?.roles?.length
                            ? parseInt(user?.roles[0]?.id)
                            : null,
                        }))
                      }
                    >
                      {roles.map((role) => (
                        <option
                          key={role.id}
                          value={role.id}
                          selected={
                            user?.roles?.length &&
                            user.roles[0].roleId === role.id
                          }
                        >
                          {role.name}
                        </option>
                      ))}
                    </Form.Control>
                  </td>
                  <td>
                    {dataUpdate?.userId === user?.id && (
                      <Button
                        onClick={() => {
                          setDataUpdate((prev) => ({
                            ...dataUpdate,
                            userId: null,
                          }));
                          setEditBtn((prev) => null);
                          document.querySelector(
                            `select[name=select-roles-${index}`
                          ).value = user?.roles[0].roleId;
                        }}
                        variant="outline-danger"
                        className="btn btn-sm float-right m-1"
                        title="Cancelar"
                      >
                        <CancelIcon title="Cancelar" />
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        dataUpdate?.userId === user?.id
                          ? handleEdit()
                          : setDataUpdate((prev) => ({ userId: user.id }));
                        setEditBtn((prev) => index);
                      }}
                      ref={editButton}
                      variant={
                        dataUpdate?.userId === user?.id
                          ? "outline-success"
                          : "outline-primary "
                      }
                      className="btn btn-sm float-right m-1"
                      title={
                        dataUpdate?.userId === user?.id
                          ? "Guardar"
                          : "Actualizar perfil"
                      }
                    >
                      {dataUpdate?.userId === user?.id ? (
                        <SaveIcon title="Guardar mis cambios" />
                      ) : (
                        <EditIcon title="Actualizar mi información" />
                      )}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
      <FooterPage />
    </>
  );
}
