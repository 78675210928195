import requests from "../utils/requests";

export const getCitiesByProvinceId = async ({ provinceId }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/provinces/${provinceId}/cities?filter[order]=name%20ASC`;
  return await requests(url, "GET");
};

export const getProvinces = async () => {
  const url = `${process.env.REACT_APP_API_URL}/api/provinces?filter[order]=name%20ASC`;
  return await requests(url, "GET");
};
