import styled from "styled-components";

const hoverScale = `
transform: scale(1);
transform-style: preserve-3d;
transition: all .2s cubic-bezier(0.075, 0.82, 0.165, 1);
&:hover
{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    transform: scale(1.1);
    transform-origin: center;
    z-index: 1;
}  
`;

export const H1 = styled.h1`
  color: #2d572c;
  font-weight: bold;
`;

export const H2b = styled.h5`
  color: #e6b219;
`;

export const H2d = styled.h5`
  color: #85bb65;
`;

export const TXTCARD = styled.h2`
  text-align: right;
  color: #00bb2d;
`;

export const AMTXT = styled.h3`
  text-align: center;
  color: #b52e1c;
`;

export const CARDFOOTERTEXT = styled.h4`
  text-align: center;
  color: #b52e1c;
`;
export const INFOCAR = styled.p`
  color: #9b9b9b;
`;

export const ListParent = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const IMG = styled.img`
  background: 9da1aa;
`;

export const TXTRELATION = styled.h4`
  font-weight: bold;
`;

export const List = styled.ul`
  display: flex;
  list-style: none;
  top: 0;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
  justify-content: center;
  // overflow-y: hidden;
`;

export const Item = styled.li`
  padding: 0px 0px;
`;

export const PaginationBtn = styled.button`
  background-color: #8a9597;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  vertical-align: middle;
  font-weight: 400;
  border: 0;
  ${hoverScale}
`;

export const PaginationBtnActive = styled.button`
  background-color: #2d572c;
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  vertical-align: middle;
  font-weight: 400;
  border: 0;
  ${hoverScale}
`;

export const TABLE = styled.table`
  text-align: Center;
`;

export const TEXTCURRENCY = styled.h5`
  font-weight: bold;
  color: #8a9597;
  text-align: center;
  margin-right: 2px;
  padding: 0;
`;

export const TEXTGOLD = styled.h4`
  font-weight: bold;
  color: #ae8625;
  text-align: center;
  margin-right: 2px;
  padding: 0;
`;

export const TEXTALERT = styled.p`
  font-weight: bold;
  color: #8a9597;
`;

export const TEXTMARKET = styled.p`
  font-weight: bold;
  color: #a45e04;
`;

export const TEXTMARKETCARD = styled.h4`
  font-weight: bold;
  color: #a45e04;
`;

export const TEXTLT = styled.h1`
  font-family: homeFont;
  font-size: 60px;
  color: #fdfefe;
`;

export const TEXTAM = styled.h1`
  font-family: homeFont;
  font-size: 60px;
  color: #fdfefe;
`;
