import React, { useRef, useState, useContext } from "react";
import { useLocation } from "wouter";
import { Alert, Button } from "react-bootstrap";
import swal from "sweetalert";
import { BiLoader } from "react-icons/bi";
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import { GetMakeModel, GetVersion } from "../../../utils/publicMethods";
import CaroselComponent from "../../Carousel";
import { EditIcon } from "../../../utils/styled-components/icons";
import { saveFavorite, deleteFavorite } from "../../../services/publication";
import useUser from "../../../hooks/useUser";
import { STATUS_PUBLICATION } from "../../../utils/constants";
import { getTimeFromNow } from "../../../utils/publicMethods";
import { TEXTMARKET } from "../styles";
import Context from "context/UserContext";

export default function PublicationCard({
  userPublication,
  data,
  carousel = true,
  editBtn,
  handleStatus,
  handleDelete,
  displayDeleteSwall,
  disabledStatusButton,
  setPublicationList,

  ...rest
}) {
  const { isLogged } = useUser();
  const [, setLocation] = useLocation();
  const [loadStateButton, setLoadStateButton] = useState(false);
  const { isAdmin } = useContext(Context);

  const refStatusDelete = useRef();
  const refStatusActive = useRef();
  const refStatusPause = useRef();

  const handleFavorite = async () => {
    if (!isLogged) {
      swal({
        text: "Debe iniciar sesión primero",
        icon: "warning",
      });
      return;
    }
    const isFavorite = data?.favorites?.length || data?.isFavorite;
    setLoadStateButton(true);
    try {
      if (isFavorite) {
        await deleteFavorite(data.id);
      } else {
        await saveFavorite(data.id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      let index = data.index;
      setPublicationList((prev) => {
        let list = [...prev];
        list[index].isFavorite = !isFavorite || !prev[index].isFavorite;
        return list;
      });
    }
    setLoadStateButton(false);
  };

  const handleStatusButtons = (status) => {
    return data.statusId === status;
  };
  const formatPrice = () => {
    if (data.price) {
      const currencyType = data?.currencyType || "$";
      const price = data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return `${currencyType} ${price}`;
    }
  };
  const EditFavButton = ({ ...rest }) => {
    const isFavorite = data?.favorites?.length || data?.isFavorite;
    const colorButton = isFavorite ? true : false;
    return (
      <button
        className={`custom-card-component__edit-fav ${
          isFavorite ? "isFav" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          !editBtn ? handleFavorite() : setLocation(`/publication/${data.id}`);
        }}
        title={`${!editBtn ? "Agregar a favoritos" : "Editar publicación"}`}
        {...rest}
      >
        {!editBtn ? (
          loadStateButton ? (
            <BiLoader className="custom-card-component__edit-fav__loader" />
          ) : isFavorite ? (
            <IoMdHeart
              key={`fav-btn-${data.id}-icon`}
              className="animated tada"
              style={{ fontSize: "20px" }}
            />
          ) : (
            <IoMdHeartEmpty
              key={`fav-btn-${data.id}-icon`}
              style={{ fontSize: "20px" }}
            />
          )
        ) : (
          <EditIcon
            key={`fav-btn-${data.id}-icon`}
            color={colorButton}
            scale={1.7}
          />
        )}
      </button>
    );
  };
  return (
    <a
      key="link-to"
      className="card-anchor_item"
      href={`/vehicle/detail/${data.slugUrl}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="custom-card-component shadow-sm" {...rest}>
        <EditFavButton key={`fav-btn-${data.id}`} />
        <div className="custom-card-component__card-header">
          <div>
            <GetMakeModel
              data={data}
              className="custom-card-component__card-header__title montserrat"
            />
          </div>
          <h6 className="text-mu0ted custom-card-component__card-header__price mb-0">
            {formatPrice() || "•"}{" "}
            {data?.marketDiscount ? (
              <TEXTMARKET className="m-1">¡Precio bajo info!</TEXTMARKET>
            ) : (
              ""
            )}
          </h6>
        </div>
        <div className="div-cards-related-component">
          {carousel ? (
            <CaroselComponent
              className="custom-card-component__card-body__carousel"
              images={data?.imageUrl || data?.publicationMediaResources}
            />
          ) : (
            <div
              className="custom-card-component__card-body__img"
              style={{
                backgroundImage: `url(${
                  data?.imageUrl || data?.publicationMediaResources[0]?.url
                })`,
              }}
            >
              <div className="overlay-blur"></div>
              <img
                src={data?.imageUrl || data?.publicationMediaResources[0]?.url}
                className=""
                alt=""
              />
            </div>
          )}
        </div>
        <div className="custom-card-component__card-footer">
          <p className="text-muted text-right mb-0 fw-400">
            {getTimeFromNow(data.modifiedAt)}
          </p>
          <hr className="my-1" />

          {!editBtn && !disabledStatusButton && (
            <div
              className="mt-2 d-flex align-items-center justify-content-center flex-wrap"
              style={{ gap: "5px" }}
            >
              <div className="badge badge-primary">
                {data?.year !== 0 && `Año ${data?.year}`}
              </div>
              <div className="badge badge-info">
                {data?.km !== 0 &&
                  `${data?.km
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${
                    data?.vehicleCategoryId === 3 ? "horas" : "km"
                  }`}
              </div>
              <small className="text-muted">
                <GetVersion data={data} />
              </small>
            </div>
          )}

          {editBtn && disabledStatusButton && data.statusId !== STATUS_PUBLICATION.CANCELED && (
            <div className="m-2">           
              <Button
                ref={refStatusPause}
                id="pause"
                onClick={(event) =>
                  handleStatus(data.id, STATUS_PUBLICATION.PAUSE, event)
                }
                className="m-1"
                variant={
                  handleStatusButtons(STATUS_PUBLICATION.PAUSE)
                    ? "warning"
                    : "secondary"
                }
                size="sm"
                disabled={handleStatusButtons(STATUS_PUBLICATION.PAUSE)}
              >
                Pausar
              </Button>
              <Button
                ref={refStatusDelete}
                id="delete"
                onClick={(event) => displayDeleteSwall(data.id, event)}
                className="m-1"
                variant={
                  handleStatusButtons(STATUS_PUBLICATION.CANCELED)
                    ? "danger"
                    : "secondary"
                }
                size="sm"
                disabled={handleStatusButtons(STATUS_PUBLICATION.CANCELED)}
              >
                Eliminar
              </Button>

              { ( (isAdmin()) || ( data.personId == userPublication && data.statusId !== STATUS_PUBLICATION.CANCELED ) ) &&
              
                <Button
                  ref={refStatusActive}
                  id="active"
                  onClick={(event) =>
                    handleStatus(data.id, STATUS_PUBLICATION.ACTIVE, event)
                  }
                  className="m-1"
                  variant={
                    handleStatusButtons(STATUS_PUBLICATION.ACTIVE)
                      ? "success"
                      : "secondary"
                  }
                  size="sm"
                  disabled={handleStatusButtons(STATUS_PUBLICATION.ACTIVE)}
                >
                  Activo
                </Button>        
              }
            </div>
          )}

          {!editBtn && disabledStatusButton && (
            <div className="m-2">
              <Button
                ref={refStatusDelete}
                id="delete"
                onClick={(event) => displayDeleteSwall(data.id, event)}
                className="m-1"
                variant={
                  handleStatusButtons(STATUS_PUBLICATION.DELETE)
                    ? "danger"
                    : "secondary"
                }
                size="sm"
                disabled={handleStatusButtons(STATUS_PUBLICATION.DELETE)}
              >
                Eliminar
              </Button>

              <Button
                ref={refStatusActive}
                id="active"
                onClick={(event) =>
                  handleStatus(data.id, STATUS_PUBLICATION.ACTIVE, event)
                }
                className="m-1"
                variant={
                  handleStatusButtons(STATUS_PUBLICATION.ACTIVE)
                    ? "success"
                    : "secondary"
                }
                size="sm"
                disabled={handleStatusButtons(STATUS_PUBLICATION.ACTIVE)}
              >
                Activo
              </Button>
            </div>
          )}

          { data.statusId === STATUS_PUBLICATION.CANCELED && (
            <div
              className="mt-2 d-flex align-items-center justify-content-center flex-wrap"
              style={{ gap: "5px" }}
            >
              <Alert variant="danger">
                <Alert.Heading>SUSPENDIDO!</Alert.Heading>
                <p className="mb-0">
                  El periodo gratis ha finalizado para esta publicación (2
                  meses). Podes comunicarte con nosotros para extender tu
                  tiempo.
                </p>
              </Alert>
            </div>
          )}
        </div>
      </div>
    </a>
  );
}
