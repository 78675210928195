import React from "react";
import { Carousel } from "react-bootstrap";

export default function CarouselComponent({
  images,
  classes,
  principal = false,
  interval = 10000,
}) {
  return (
    <Carousel className={classes ?? ""} interval={interval}>
      {images.map((image) => (
        <Carousel.Item key={`carousel-${image.id}`}>
          <img
            className={
              image.class ?? principal
                ? "imgPrincipalCarouselAndAd"
                : "imgCarDetail"
            }
            src={image?.url}
            alt={image?.alt || ""}
          />
          {principal && (
            <div className="carousel-principal-text">
              <p className="--principal mb-0">{image?.principalText}</p>
              <p className="mb-0">{image?.secText}</p>
            </div>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
