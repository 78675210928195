import React from "react";
import { OrderByContextProvider } from "../../context/OrderByContext";
import { FilterByContextProvider } from "../../context/FilterByContext";
import BulkUpdateComponents from "components/Publication/BulkUpdateComponents";
import NavBar from "layouts/NavBar";
import FooterPage from "components/Footer/FooterPage";
import GoBackComponent from "components/GoBackComponent";


export default function BulkUpdatePage() {
return (
  <>
  <NavBar></NavBar>
    <GoBackComponent/>
    <div className="publicationshome-wrapper px-3 mb-2 mt-4">
    <div className="publicationshome-wrapper__left">
      <OrderByContextProvider>
        <FilterByContextProvider>
         <BulkUpdateComponents/>
        </FilterByContextProvider>
      </OrderByContextProvider>  
    </div>
    </div>
    
    <FooterPage />
  </>
);
}