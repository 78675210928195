import React from "react";
import { WpIcon } from "../utils/styled-components/icons";

export default function ButtonWp({ cellPerson, emailPerson, url }) {
  const textToSend = emailPerson
    ? `Hola!, Nos comunicamos desde la web AutosMisiones.com  por su publicacion url: autosmisiones.com${url}`
    : `Hola!, Me interesa tu vehiculo publicado en *autosmisiones.com*\n url: autosmisiones.com${url}`;
  return (
    <a
      className="btn-social --wapp"
      target="_blank"
      href={`https://wa.me/${cellPerson}?text=${textToSend}`}
      rel="noreferrer"
    >
      <WpIcon style={{ fontSize: "20px" }} />
    </a>
  );
}
