import {
  Col,
  Container,
  Row,
  InputGroup,
  Button,
  Spinner,
  Alert,
  Nav,
  Tooltip,
} from "react-bootstrap";
import { EnvelopeIcon, KeyIcon } from "../utils/styled-components/icons";
import React, { useEffect, useState } from "react";
import { useLocation } from "wouter";
import useUser from "../hooks/useUser";
import {
  LOGINBUTTON,
  IMG,
  TEXTAM,
  INPUTCTRL,
} from "./../components/SignInOutStyles";

export default function SignInPage() {
  const [, setLocation] = useLocation();

  const { login, isLogged, isLoginLoading, hasLoginError, loginErrorMessage } =
    useUser();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  

  useEffect(() => {
    if (isLogged) setLocation("/");
  }, [isLogged, setLocation]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    login(formData);
  };

  return (
    <Container
      fluid
      className="signUpImage d-flex justify-content-center align-items-center"
    >
      <Row>
        <Col sm="12" md="6" className="order-1 order-sm-0">
          <form onSubmit={handleSubmit}>
            <Col className="signUpPageForm">
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <IMG
                    className="d-flex 
                    justify-content-center
                    align-items-center"
                  >
                    <EnvelopeIcon className="iconAM" size={17} />
                  </IMG>
                </InputGroup.Prepend>
                <INPUTCTRL
                  placeholder="Correo electrónico"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                  type="email"
                  name="email"
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <IMG className="d-flex justify-content-center align-items-center">
                    <KeyIcon className="iconAM" size={17} />
                  </IMG>
                </InputGroup.Prepend>
                <INPUTCTRL
                  placeholder="Contraseña"
                  aria-label="Password"
                  aria-describedby="basic-addon1"
                  type="password"
                  name="password"
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              <InputGroup className="mb-3 justify-content-center">
                <LOGINBUTTON
                  type="button"
                  className="px-3 m-1"
                  onClick={() => setLocation("/signup")}
                >
                  Registrarse
                </LOGINBUTTON>
                {isLoginLoading ? (
                  <Button className="float-right" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Iniciando...
                  </Button>
                ) : (
                  <LOGINBUTTON type="submit" className="px-3 m-1">
                    Ingresar
                  </LOGINBUTTON>
                )}
              </InputGroup>
              {hasLoginError && (
                <Alert variant="danger">
                  {`${loginErrorMessage}. Intente nuevamente.`}
                </Alert>
              )}
              <p>
                Si has olvidado tu contraseña haz click{" "}
                <a href="/forget-password">aqui</a>
              </p>
            </Col>
          </form>
        </Col>

        <Col
          sm="12"
          md="6"
          className="col-md-offset-1
          order-0 order-sm-1"
        >
          <Nav.Link href="/">
            <img
              className="imgLogo"
              src="img/logoAm.png"
              alt="logo auto misiones"
              title="Ir al inicio"
            />
            
            <span className="d-none d-md-block">
              <TEXTAM>Autos Misiones</TEXTAM>
            </span>
          </Nav.Link>
        </Col>
      </Row>
    </Container>
  );
}
