import React from "react";
import brandLogo from "../assets/images/brandIcon.png";

export default function AmImg() {
  return (
    <>
      <div className="text-center">
        <img
          src={brandLogo}
          //width="100"
          height="150"
          alt="logo auto misiones"
        />
      </div>
    </>
  );
}
