import React from "react";
import { Card, Form, Row, Col, Button, Container } from "react-bootstrap";
import ImgItem from "../ImgItem";

export default function MultimediaTab(props) {
  return (
    <Container>
      <Card>
        <Card.Body>
          <Card.Text>
            <input
              type="file"
              name="file"
              id="file"
              onChange={props.onChangeHandler(this)}
              multiple
              className="inputfile"
            />
            <label htmlFor="file">Cargar imágenes</label>
            <Row>
              {props.selectedFile.length > 0 &&
                Array.from(props.selectedFile).map(
                  (file, index) =>
                    !file?.delete && (
                      <Col
                        sm={12}
                        md={2}
                        className="d-flex justify-content-center"
                        key={index}
                      >
                        <ImgItem
                          fileName={file.name}
                          deleteFile={props.deleteFile}
                          index={index}
                          imagePreviewUrl={file.imagePreviewUrl}
                        />
                      </Col>
                    )
                )}
            </Row>
          </Card.Text>
        </Card.Body>

        <Card.Footer>
          <small className="text-muted">
            Únicamente imágenes (PNG, JPG y JPEG)
            <br />
            Máximo 5 imágenes
            <br />
            Tamaño máximo por imágen 500KB
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Form.Row>
        <Col>
          <Button
            disabled={props.loading}
            onClick={props.handleSubmit(this)}
            type="button"
            className="float-right mx-2"
            id="edit"
          >
            {props.editBtn ? "Editar" : "¡Publicar!"}
          </Button>

          <Button
            onClick={props.previousTab}
            type="button"
            className="float-right mx-2"
          >
            Atrás
          </Button>
        </Col>
      </Form.Row>
    </Container>
  );
}
