import axios from "axios";

const hashSwitch = async (url, method, params) => {
  switch (method) {
    case "POST":
      try {
        return await axios.post(url, params);
      } catch (error) {
        console.log(`Error performing method: ${method} - ${url}`);
        return error.response.data;
      }
    case "GET":
      try {
        return await axios.get(url, params);
      } catch (error) {
        console.log(`Error performing method: ${method} - ${url}`);
      }
      break;
    case "PUT":
      try {
        return await axios.put(url, params);
      } catch (error) {
        console.log(`Error performing method: ${method} - ${url}`);
      }
      break;
    case "PATCH":
      try {
        return await axios.patch(url, params);
      } catch (error) {
        console.log(`Error performing method: ${method} - ${url}`);
      }
      break;
    case "DELETE":
      try {
        return await axios.delete(url, params);
      } catch (error) {
        console.log(`Error performing method: ${method} - ${url}`);
      }
      break;
    default:
      return;
  }
};

export const parseObjToQuery = (obj) => {
  const params = Object.keys(obj).map((key) => {
    return `${key}=${obj[key]}`;
  });
  return `?${params.join("&")}`;
};

export default hashSwitch;
