import styled from "styled-components";

export const TEXTFLAG = styled.span`
  font-size: 12px;
`;

export const TEXTPHONE = styled.span`
  font-weight: bold;
`;
export const TEXTFRIEND = styled.span`
  font-weight: bold;
  font-size:15px;
  color: #5f2c1c;
  transition: color 0.3s; /* Transición suave */
  &:hover {
    color: green; /* Color al pasar el cursor */
  }
`;
