import React, { useState } from "react";
const Context = React.createContext({});
export function OrderByContextProvider({ children }) {
  const [orderBy, setOrderBy] = useState("modifiedAt DESC");

  return (
    <Context.Provider value={{ orderBy, setOrderBy }}>
      {children}
    </Context.Provider>
  );
}

export default Context;
