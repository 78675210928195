import React from "react";

import useWindowDimensions from "hooks/useWindowDimensions";
import PrincipalCarouselAndAd from "components/Publication/PrincipalCarouselAndAd";

import NavBar from "./NavBar";
import PublicationsComponent from "./../components/Publication/Publications";
import AdColumn from "../components/RightColumn";
import { OrderByContextProvider } from "../context/OrderByContext";
import { FilterByContextProvider } from "../context/FilterByContext";
import PrincipalAdvertisingColumn from "../components/PrincipalAdvertisingColumn";
import FooterPage from "../components/Footer/FooterPage";
import { WpIcon } from "../utils/styled-components/icons";
import ConcessionaireList from "components/Concessionaire/ConcessionaireList";

export default function MainLayout() {
  const { width } = useWindowDimensions();
  return (
    <>
      <NavBar />
      <PrincipalAdvertisingColumn />
      <PrincipalCarouselAndAd />
      {width < 700 && (
        <a
          style={{
            width: "auto",
            maxWidth: "150px",
            order: -1,
          }}
          id="button"
          className="publicationshome-wrapper__right__wapp-btn my-4 mx-auto"
          //  Agregar esta propiedad como configuracion en bd ⬇️
          href={`https://wa.me/5493765050405`}
          target="_blank"
          rel="noreferrer"
        >
          <WpIcon className="mr-1" color="white" size={20} />
          <span>Contactanos!</span>
        </a>
      )}
      <div className="publicationshome-wrapper px-3 mb-2 mt-4">
        <div className="publicationshome-wrapper__left">
          <OrderByContextProvider>
            <FilterByContextProvider>
              <PublicationsComponent />
            </FilterByContextProvider>
          </OrderByContextProvider>
          <hr className="mt-4 mb-0" />
          <ConcessionaireList />
        </div>
        {width > 700 && (
          <div className="publicationshome-wrapper__right">
            <a
              id="button"
              className="publicationshome-wrapper__right__wapp-btn"
              //  Agregar esta propiedad como configuracion en bd ⬇️
              href={`https://wa.me/5493765050405`}
              target="_blank"
              rel="noreferrer"
            >
              <WpIcon className="mr-1" color="white" size={20} />
              <span>Contactanos!</span>
            </a>
            <div className="publicationshome-wrapper__right__ad shadow">
              <AdColumn />
            </div>
          </div>
        )}
      </div>

      <FooterPage />
    </>
  );
}
