export const CurrencyFormatter = new Intl.NumberFormat("de-De", {
  maximumFractionDigits: 0,
});

export function copyToClipboard(text) {
  try {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    navigator.clipboard.writeText(textArea.value);
    document.body.removeChild(textArea);
  } catch (err) {
    alert("No se pudo copiar al portapapeles");
  }
}

export const formatNumberWithCommas = (number) => {
  const formatter = new Intl.NumberFormat("es-ES");
  return formatter.format(number);
};
