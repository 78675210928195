import React, { useEffect, useState } from "react";
import { getBussiness } from "services/userAccount";
import { TEXTNEWCONCESS } from "components/Footer/styles";

export default function ConcessionaireList() {
  const [bussinessData, setBussinessData] = useState([]);

  useEffect(() => {
    const getBussinessData = async () => {
      const { data } = await getBussiness();
      const people = data.response
        .filter((person) => {
          const image = person.personMediaResources.find(
            (personMediaResource) => personMediaResource.imageType === "card"
          );
          return true;
        })
        .map((person) => ({
          ...person.personMediaResources.find(
            (personMediaResource) => personMediaResource.imageType === "card"
          ),
          firstName: person.firstName,
          lastName: person.lastName,
        }));
      setBussinessData(people);
    };
    getBussinessData();
  }, []);

  return (
    <div
      className="div-cards-related-component m-2 p-3"
      style={{
        backgroundImage: `url("/img/filaAutosNegrosPortada.webp")`,
      }}
    >
      <div className="div-cards-related-component justify-content-center align-items-center">
        {Array.isArray(bussinessData) && bussinessData.length > 0 ? (
          bussinessData?.map((person) => (
            <a href={`/concessionaire/${person.personId}`}>
              <div className="div-cards-related-component__cards__item">
                <div className="custom-card-related-component__concess shadow-sm">
                  <div className="custom-card-component__card-body__img">
                    <img src={person.url} alt="Concesionaria" />
                  </div>
                  <span className="textCardConcess m-3">
                    {person.lastName} {person.firstName}
                  </span>
                </div>
              </div>
            </a>
          ))
        ) : (
          <TEXTNEWCONCESS>
            Contactanos y registrate como concesionario
          </TEXTNEWCONCESS>
        )}
      </div>
    </div>
  );
}
