import { AiOutlineLoading } from 'react-icons/ai'
import { SearchIcon } from 'utils/styled-components/icons'

export default function InputSearch({ name, handleSearch, size, placeholder, type, classes, classes_in, keyUpHandle, isTyping, style_out, style_in, ...rest }) {
  return (
    <div className={`${classes && classes} input-search`} style={style_out && style_out}>
      <input
        {...rest}
        type={`text`}
        autoComplete="OFF"
        name={name}
        required
        id={name}
        style={style_in && style_in}
        onChange={(e) => { handleSearch && handleSearch(e) }}
        className={`input-search__input ${classes_in ? classes_in : ''}`} />
      <label htmlFor={name} className="input-search__label">{placeholder}</label>
      <div className="input-search__icon">
        {isTyping ? <AiOutlineLoading className="load" /> : <SearchIcon />}
      </div>
    </div>
  )
}