import React from "react";
import { Card, Button } from "react-bootstrap";
import { TrashIcon } from "../utils/styled-components/icons";

export default function ImgItem(props) {
  return (
    <Card style={{ width: "10rem" }} className="m-1" id={props.fileName}>
      <Card.Img src={props.imagePreviewUrl} />
      <Card.Footer className="m-0 p-0">
        <Button
          type="button"
          size="sm"
          variant="outline-danger"
          className="float-right"
          value={props.index}
          onClick={(event) => props.deleteFile(event)}
        >
          <TrashIcon title="Eliminar" />
        </Button>
      </Card.Footer>
    </Card>
  );
}
