export const TABLE_ATTRIBUTES = [
  "condition",
  "color",
  "transmision",
  "engine",
  "fuelType",
  "doors",
  "uniqueOwner",
];

export const TABLE_ATTRIBUTES_I = [
  { attrName: "condition", nameEs: "condición" },
  { attrName: "color", nameEs: "color" },
  { attrName: "transmision", nameEs: "transmisión" },
  { attrName: "engine", nameEs: "tipo de motor" },
  { attrName: "fuelType", nameEs: "tipo de combustible" },
  { attrName: "doors", nameEs: "cantidad de puertas" },
  { attrName: "uniqueOwner", nameEs: "es unico dueño" },
  { attrName: "swap", nameEs: "Acepta permuta" },
];

export const publication = [
  "km",
  "year",
  "price",
  "vehicleVersion",
  "vehicleModel",
  "vehicleMake",
  "imageUrl",
];

export const STATUS_PUBLICATION = {
  ACTIVE: 1,
  PAUSE: 2,
  CANCELED: 3,
  SOLD: 4,
  IN_DRAFT: 5,
};

export const STATUS_LABEL = {
  1: {
    name: "Activo",
    color: "success",
  },
  2: {
    name: "Pausado",
    color: "warning",
  },
  3: {
    name: "Eliminado",
    color: "danger",
  },
  4: {
    name: "Suspendido",
    color: "info",
  },
  /* 5: {
    name: "En borrador"
    color: "primary",
  }, */
};
