import React, { useContext } from "react";
import { Form, Col } from "react-bootstrap";
import Context from "../../../context/OrderByContext";

export default function OrderForm({ onHide }) {
  const { setOrderBy } = useContext(Context);
  const handleOrderBy = (event) => {
    const val = event.target.value;
    setOrderBy(val);
    onHide(true);
  };
  return (
    <Form>
      <Form.Group as={Col} controlId="formGridState">
        <Form.Control
          as="select"
          defaultValue="Ordenar por..."
          onChange={handleOrderBy}
        >
          <option value="">Ordenar</option>
          <option value="year DESC">Año/Modelo</option>
          <option value="modifiedAt DESC">Más recientes</option>
          <option value="modifiedAt ASC">Menos recientes</option>
          <option value="price ASC">Menor precio</option>
          <option value="price DESC">Mayor precio</option>
        </Form.Control>
      </Form.Group>
    </Form>
  );
}
