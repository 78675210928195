import React from "react";
import NavBar from "../../layouts/NavBar";
import { OrderByContextProvider } from "../../context/OrderByContext";
import { FilterByContextProvider } from "../../context/FilterByContext";
import AllPublicationComponent from "../../components/Publication/AllPublicationComponent";
import FooterPage from "components/Footer/FooterPage";
import GoBackComponent from "components/GoBackComponent";

export default function AllPublicationsPage() {
return (
  <>
  <NavBar></NavBar>
    <GoBackComponent/>

    <div className="publicationshome-wrapper px-3 mb-2 mt-4">
    <div className="publicationshome-wrapper__left">
      <OrderByContextProvider>
        <FilterByContextProvider>
         <AllPublicationComponent/>
        </FilterByContextProvider>
      </OrderByContextProvider>  
    </div>
    </div>
    
    <FooterPage />
  </>
);
}
