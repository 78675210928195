import styled from "styled-components";

export const LOGINBUTTON = styled.button`
  background-color: #2d572c;
  color: #fdfefe;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  padding: 8px 16px;
  border-radius: 10rem;
  line-height: 1;
  cursor: pointer;
  border: 0;
`;

export const FORMCTRL = styled.input`
  font-family: buttonFont;
  text-align: center;
  color: #2d572c;
  font-size: 17px;
  width: 100%;
  height: 40px;
  border-radius: 0.5rem;
  border-color: #2d572c;
`;

export const INPUTCTRL = styled.input`
  flex: 1;
  font-size: 17px;
  width: 100%;
  height: 37px;
`;

export const TEXTOPTION = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #2d572c;
  font-family: buttonFont;
`;

export const TEXTOPTIONS = styled.p`
  font-weight: bold;
  font-size: 25px;
  color: #2d572c;
  font-family: buttonFont;
`;

export const TEXTADV = styled.p`
  font-weight: bold;
  font-size: 30px;
  color: #122a0a;
  font-family: buttonFont;
`;

export const TEXTCONCESS = styled.p`
  font-weight: bold;
  font-size: 43px;
  color: #122a0a;
  font-family: fantasy;
`;

export const TEXTADVSEC = styled.p`
  font-size: 18px;
  color: #ffffff;
  font-family: sans-serif;
`;

export const TEXTLT = styled.h1`
  font-family: homeFont;
  font-size: 60px;
  color: #fdfefe;
`;

export const TEXTAM = styled.h1`
  font-family: homeFont;
  font-size: 50px;
  color: #2d572c;
`;

export const TEXTAMTWO = styled.h1`
  font-family: monospace;
  font-weight: bold;
  font-size: 40px;
  color: #2d572c;
`;

export const TEXTSEC = styled.h2`
  font-weight: bold;
  color: #6e6e6e;
`;

export const TEXTSECTWO = styled.h2`
  color: #ffffff;
`;

export const IMG = styled.div`
  width: 40px;
  height: 37px;
  background-color: #274926;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
`;

export const FCBOOK = styled.p`
  flex: 1;
  color: #3b5998;
  font-weight: bold;
  font-size: 17px;
  width: 100%;
`;

export const GOOGL = styled.p`
  color: #db4a39;
  font-weight: bold;
  font-size: 17px;
`;

export const IMGAM = styled.img`
  width: {
  }
`;
