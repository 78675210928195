import dayjs from "dayjs";
// No tengo idea por que es necesario importar esta libreria para que funcione el dayjs en español
import { es } from "dayjs/locale/es";
const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);
dayjs.locale("es");

export const maxSelectFile = (event, filesFromStatusCount) => {
  const files = event.target.files;
  const filesFromInputCount = files.length;
  const filesCount = filesFromInputCount + filesFromStatusCount;

  if (filesCount > 5) {
    const msg = "Solo puede subir 5 imágenes";
    event.target.value = null;
    console.log(msg);
    return false;
  }

  return true;
};

export const checkMimeType = (event) => {
  const files = event.target.files;
  const types = ["image/png", "image/jpeg", "image/jpg"];

  let err = "";

  for (let x = 0; x < files.length; x++) {
    if (types.every((type) => files[x].type !== type)) {
      err += `${files[x].type} no es un formato válido.`;
    }
  }

  if (err !== "") {
    event.target.value = null;
    console.log(err);
    return false;
  }

  return true;
};

export const maxFileSize = (event) => {
  let files = event.target.files;
  let err = "";
  let maxSize = 500000;

  for (let x = 0; x < files.length; x++) {
    if (files[x].size > maxSize) {
      err += `${files[x].name} excede el tamaño permitido (500KB por archivo).`;
    }
  }

  if (err !== "") {
    event.target.value = null;
    console.log(err);

    return false;
  }

  return true;
};

export const inputPriceMask = (event) => {
  const input = event.target.value;

  if (input) {
    const regex = /^[0-9]*$/;
    const regex2 = /^[0-9]*\.[0-9]*$/;

    if (!regex.test(input) && !regex2.test(input)) {
      event.target.value = input.substring(0, input.length - 1);
    }
  }
};

export const getTimeFromNow = (date) => {
  if (!date) {
    return "N/A";
  }
  return dayjs().to(dayjs(date));
};

export const GetMakeModel = ({ data, ...rest }) => {
  const {
    customId,
    customMake,
    customModel,
    vehiculoVersion,
    makeId,
    vehicleMake,
    vehicleModel,
    vehicleCustomData,
  } = data;
  let dataToReturn = "";
  if (customId || makeId) {
    dataToReturn = customId
      ? `${customMake} - ${customModel}`
      : `${vehicleMake} - ${vehicleModel}`;
  } else if (vehicleCustomData?.id) {
    const { make, model } = vehicleCustomData;
    dataToReturn = `${make} - ${model}`;
  } else if (vehiculoVersion) {
    const {
      vehicleModel,
      vehicleModel: {
        vehicleMake: { name },
      },
    } = vehiculoVersion;
    dataToReturn = `${name} - ${vehicleModel?.name}`;
  }
  return <span {...rest}>{dataToReturn || `AutosMisiones`}</span>;
};

export const GetVersion = ({ data, ...rest }) => {
  const {
    customId,
    versionId,
    customVersion,
    vehicleVersion,
    vehiculoVersion,
  } = data;
  let dataToReturn = "";
  if (customId || versionId) {
    dataToReturn = customId ? `${customVersion}` : `${vehicleVersion}`;
  } else if (vehiculoVersion) {
    const { name } = vehiculoVersion;
    dataToReturn = `${name}`;
  }
  return <span {...rest}>{dataToReturn || ``}</span>;
};
