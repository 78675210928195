import React, { useState, useEffect, useRef } from "react";
import { Col, Card, Button, Row } from "react-bootstrap";
import { Link } from "wouter";
import swal from "sweetalert";

import {
  getUserImages,
  postPersonMultimedia,
} from "../../services/userAccount";
import { getLocalStorage } from "../../utils/localStorage";
import { checkMimeType, maxFileSize } from "utils/publicMethods";
import InputFile from "./Input/InputFile";

import { ExternalLinkAltIcon } from "utils/styled-components/icons";

const IMAGE_TYPES = {
  LOGO: "logo",
  COVER: "cover",
  CARD: "card",
};

export default function ImagesConcess() {
  const [personId] = useState(() => getLocalStorage("userId"));
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const editButton = useRef(null);
  const [editBtn, setEditBtn] = useState(false);

  useEffect(() => {
    getImages();
    console.log("editBtn" + editBtn);
  }, []);

  useEffect(() => {
    async function handleLoading() {
      if (loading && !swal.getState().isOpen) {
        swal({
          title: "Cargando...",
          buttons: false,
        });
      } else if (swal.getState().isOpen) {
        swal.close();
      }
    }
    handleLoading();
  }, [loading, editBtn]);

  const getImages = async () => {
    const imagesData = await getUserImages({ personId });
    await setSelectedFile(() => {
      return imagesData.data.map((file, index) => {
        return {
          index: index,
          imagePreviewUrl: file.url,
          name: file.imageType,
          imageType: file.imageType,
          alreadyExists: true,
          delete: false,
        };
      });
    });
  };

  const onChangeHandler = (event) => {
    let val = event.target.name;

    if (checkMimeType(event) && maxFileSize(event)) {
      let file = event.target.files[0];
      file.imageType = val;
      file.imagePreviewUrl = URL.createObjectURL(file);

      const filesAux = selectedFile.filter(
        (f) => f.imageType !== file.imageType
      );
      setSelectedFile([...filesAux, file]);
    } else {
      swal({
        title: "Oops, verifica las imágenes",
        icon: "error",
      });
    }
  };

  const deleteFile = (event) => {
    const filePosition = event.currentTarget.value;

    if (Array.isArray(selectedFile)) {
      selectedFile?.splice(filePosition, 1);
    }

    setSelectedFile([...selectedFile]);
  };

  const submitImages = async () => {
    if (selectedFile.length !== 3) {
      swal({
        title: "Debe cargar todas las imágenes para el perfil concesionaria",
        icon: "warning",
      });
      return;
    }

    const data = new FormData();
    let errors = 0;

    for (let x = 0; x < selectedFile.length; x++) {
      try {
        data.append("file", selectedFile[x], selectedFile[x].imageType);
      } catch (error) {
        errors++;
        console.log("Not file found", error);
        continue;
      }
    }
    if (selectedFile.length !== errors) {
      setLoading(true);
      let response = await postPersonMultimedia(personId, data);
      if (response.error) {
        swal({
          title: "Error",
          text: response.error,
          icon: "error",
          button: "Ok",
        });
      } else {
        await showSwall(
          response,
          "Datos actualizados correctamente",
          "success"
        );
        setLoading(false);
        setEditBtn(false);
      }
    } else {
      swal({
        title: "De actualizar una imagen para poder guardar.",
        icon: "warning",
        button: "Ok",
      });
    }

    return true;
  };

  async function showSwall(response, successTitle, iconResponse) {
    if (!response.error) {
      setLoading(false);
      swal({
        title: successTitle,
        icon: iconResponse,
        text: "www.autosmisiones.com",
        buttons: {
          cancel: {
            text: "Cerrar",
            value: false,
            visible: true,
            closeModal: true,
          },
        },
      });
    }
  }

  return (
    <>
      <Card className="shadow-lg mb-5 bg-white rounded">
        <Card.Body className="p-3">
          <Card.Title>
            Perfil Concesionaria
            <Link href={`/concessionaire/${personId}`}>
              <Button
                variant="outline-primary"
                className="btn btn-sm float-right"
              >
                <ExternalLinkAltIcon title="Ir a mi sección" />
              </Button>
            </Link>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">IMAGENES</Card.Subtitle>

          {editBtn ? (
            <Col className="align-items-center" sm={12} md={12}>
              <Card className="shadow-lg m-1 bg-white rounded" border="success">
                <Card.Body>
                  <Card.Title>Logo</Card.Title>
                  <Card.Text>
                    <InputFile
                      name={IMAGE_TYPES.LOGO}
                      id={IMAGE_TYPES.LOGO}
                      editBtn={editBtn}
                      onChange={onChangeHandler}
                      deleteFile={deleteFile}
                      selectedFile={selectedFile?.find(
                        (file) => file.imageType === IMAGE_TYPES.LOGO
                      )}
                    />
                  </Card.Text>
                  <Card.Footer>
                    <small className="text-muted">
                      Únicamente imágenes (PNG, JPG y JPEG)
                      <br />
                      Imagen logo: min 100x150 max 200x250
                    </small>
                  </Card.Footer>
                </Card.Body>
              </Card>

              <Card className="shadow-lg m-1 bg-white rounded" border="success">
                <Card.Body>
                  <Card.Title>Portada</Card.Title>
                  <Card.Text>
                    <InputFile
                      name={IMAGE_TYPES.COVER}
                      id={IMAGE_TYPES.COVER}
                      editBtn={editBtn}
                      onChange={onChangeHandler}
                      deleteFile={deleteFile}
                      selectedFile={selectedFile.find(
                        (file) => file.imageType == IMAGE_TYPES.COVER
                      )}
                    />
                  </Card.Text>
                  <Card.Footer>
                    <small className="text-muted">
                      Únicamente imágenes (PNG, JPG y JPEG)
                      <br />
                      Imagen tarjeta Home: min 150x200 max 300x350
                    </small>
                  </Card.Footer>
                </Card.Body>
              </Card>

              <Card className="shadow-lg m-1 bg-white rounded" border="success">
                <Card.Body>
                  <Card.Title>Tarjeta</Card.Title>
                  <Card.Text>
                    <InputFile
                      name={IMAGE_TYPES.CARD}
                      id={IMAGE_TYPES.CARD}
                      disabled={!editBtn}
                      onChange={onChangeHandler}
                      deleteFile={deleteFile}
                      selectedFile={selectedFile.find(
                        (file) => file.imageType == IMAGE_TYPES.CARD
                      )}
                    />
                  </Card.Text>
                  <Card.Footer>
                    <small className="text-muted">
                      Únicamente imágenes (PNG, JPG y JPEG)
                      <br />
                      Imagen portada: min 200x300 max 400x700
                    </small>
                  </Card.Footer>
                </Card.Body>
              </Card>

              <br />
            </Col>
          ) : (
            ""
          )}
          <Row className="row justify-content-center">
            <Button
              type="button"
              className="m-2"
              onClick={() => {
                setEditBtn(true);
              }}
              ref={editButton}
              disabled={editBtn}
            >
              {selectedFile.length < 1 ? "CARGAR" : "ACTUALIZAR"}
            </Button>

            <Button
              onClick={submitImages}
              type="button"
              className="float-right m-2"
              disabled={!editBtn}
              id="edit"
            >
              GUARDAR
            </Button>

            <Button
              type="button"
              className="m-2"
              onClick={() => {
                setEditBtn(false);
              }}
              ref={editButton}
              disabled={!editBtn}
            >
              CANCELAR
            </Button>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
