import CarouselComponent from 'components/Carousel'
import { TEXTADV, TEXTADVSEC } from 'components/SignInOutStyles';
import React from 'react'

export default function PrincipalCarouselAndAd() {
  return (
    <div className='principalCarouselAndAd px-3'>
      <div className='principalCarouselAndAd__slide'>
        <CarouselComponent
          principal
          classes={"principalCarouselAndAd__slide__carousel"}
          images={[{id:0, url:"img/portadaPubliHome.jpg", principalText:"www.autosmisiones.com", secText:"Venta de vehículos nuevos y usados."},
          {id:1, url:"img/fotoTratoPortada.jpg", principalText:"Publica en www.autosmisiones.com", secText:"Se parte de esta empresa."},
          {id:2, url:"img/backgroundHomeCarousel.webp", principalText:"Vende el tuyo", secText:"Puedes publicar: autos, motos, camionetas, buses, vehículos de agua, camiones y más."}]}
        />
      </div>
      <div className="imgHome principalCarouselAndAd__ad">
        <img src="img/pruebaHome.jpg" alt="ad" />
        <span className="textAdvHome d-none d-md-block">
            <TEXTADV>AutosMisiones.com</TEXTADV>
        </span>
        <span className="textAdvSec d-none d-md-block">
            <TEXTADVSEC>Lo tiene todo...</TEXTADVSEC>
        </span>
      </div>
    </div>
  )
}
