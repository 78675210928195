import React from "react";
import { useLocation } from "wouter";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import useUser from "../hooks/useUser";
import { LOGINBUTTON as Button } from "./../components/SignInOutStyles";
import brandLogo from "../assets/images/brandIcon.png";

export default function NavBar() {
  const [, setLocation] = useLocation();
  const { isLogged, logout } = useUser();

  const sessionDestroy = () => {
    logout();
    setLocation(`/`);
  };

  return (
    <header>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Navbar.Brand>
          <Nav.Link href="/">
            <img
              className="img-fluid"
              src={brandLogo}
              width="50"
              height="50"
              alt="logo auto misiones"
            />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href={isLogged ? "/publication" : "/signin"}>
              <Button className="btn btn-success">Vender</Button>
            </Nav.Link>
          </Nav>
          <Nav style={{ marginRight: "60px" }}>
            {isLogged ? (
              <NavDropdown title="Mi usuario" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#">Mi perfil</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
                <NavDropdown.Item href="/favorites">Favoritos</NavDropdown.Item>
                <NavDropdown.Item onClick={sessionDestroy}>
                  Cerrar sesión
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <Nav.Link href="/signup">Registrarse</Nav.Link>
                <Nav.Link eventKey={2} href="/signin">
                  Iniciar sesion
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}
