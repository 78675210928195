import { useState, useEffect, useContext } from "react";
import { Form, Button, Col } from "react-bootstrap";
import FilterByContext from "../../../context/FilterByContext";
import NumberFormat from "react-number-format";
import Context from "context/UserContext";
import {
  getCategories,
  getMakesByCategoryId,
  getModelsByMakeIdAndCategoryId,
} from "../../../services/vehicleData";

export default function FilterForm({ onHide, showFilterByCategory,
  showFilterByCondition,
  showFilterByPrice,
  showFilterByMake,
  showFilterByModel,
  showFilterByVersion,
  showFilterByYear,
  showFilterByKm,
  showFilterByMarketDiscount }) {

  showFilterByCategory = showFilterByCategory === undefined ? true : showFilterByCategory;
  showFilterByMake = showFilterByMake === undefined ? true : showFilterByMake;
  showFilterByModel = showFilterByModel === undefined ? true : showFilterByModel;
  showFilterByVersion = showFilterByVersion === undefined ? true : showFilterByVersion;
  showFilterByPrice = showFilterByPrice === undefined ? true : showFilterByPrice;
  showFilterByYear = showFilterByYear === undefined ? true : showFilterByYear;
  showFilterByKm = showFilterByKm === undefined ? true : showFilterByKm;
  showFilterByCondition = showFilterByCondition === undefined ? true : showFilterByCondition;
  showFilterByMarketDiscount =showFilterByMarketDiscount === undefined ? true : showFilterByMarketDiscount;

  const [categories, setCategories] = useState([]);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  const [filterByPriceMin, setFilterByPriceMin] = useState(null);
  const [filterByPriceMax, setFilterByPriceMax] = useState(null);

  const [filterByYearMin, setFilterByYearMin] = useState(null);
  const [filterByYearMax, setFilterByYearMax] = useState(null);

  const [filterByKmMin, setFilterByKmMin] = useState(null);
  const [filterByKmMax, setFilterByKmMax] = useState(null);

  const [filterByMake, setFilterByMake] = useState(null);
  const [filterByModel, setFilterByModel] = useState(null);
  const [filterByCategory, setFilterByCategory] = useState(null);

  const [filterByCondition, setFilterByCondition] = useState(null);

  const [filterByMarketDiscount, setFilterByMarketDiscount] = useState(false);

  const [filterByPublicationCanceled, setFilterByPublicationCanceled] = useState(false);
  
  const { setFilterBy } = useContext(FilterByContext);
  const { isAdmin } = useContext(Context);

  useEffect(() => {
    async function handleGetCategories() {
      const result = await getCategories();
      setCategories(result.data);
    }
    handleGetCategories();
  }, []);

  const onChangeOptionCategory = async (event) => {
    const val = event.target.value;
    const result = await getMakesByCategoryId({
      vehicleCategoryId: val,
    });
    setMakes(result.data.response);
    handleFilterByCategory(val);
  };

  const onChangeOptionMake = async (event) => {
    const val = event.target.value;
    const vehicleCategoryId = document.getElementById(
      "vehicleCategorySelect"
    ).value;
    const result = await getModelsByMakeIdAndCategoryId({
      vehicleCategoryId,
      vehicleMakeId: val,
    });
    setModels(result.data);
    handleFilterByMake(val);
  };

  const handleFilterByPriceMin = (event) => {
    const val = event.target.value;
    setFilterByPriceMin(val);
  };

  const handleFilterByPriceMax = (event) => {
    const val = event.target.value;
    setFilterByPriceMax(val);
  };

  const handleFilterByYearMin = (event) => {
    const val = event.target.value;
    setFilterByYearMin(val);
  };

  const handleFilterByYearMax = (event) => {
    const val = event.target.value;
    setFilterByYearMax(val);
  };

  const handleFilterByKmMin = (event) => {
    const val = event.target.value;
    setFilterByKmMin(val);
  };

  const handleFilterByKmMax = (event) => {
    const val = event.target.value;
    setFilterByKmMax(val);
  };

  const handleFilterByCategory = (val) => {
    setFilterByCategory(val);
  };

  const handleFilterByMake = (val) => {
    setFilterByMake(val);
  };

  const handleFilterByModel = (event) => {
    const val = event.target.value;
    setFilterByModel(val);
  };

  const handleFilterByCondition = (event) => {
    const val = event.target.value;
    setFilterByCondition(val);
  };

  const handleFilterByMarketDiscount = (event) => {
    const val = event.target.value;
    setFilterByMarketDiscount(val);
  };

  const handleFilterPublicationCanceled = (event) => {
    const val = event.target.value;
    setFilterByPublicationCanceled(val);
  };

  const onFilter = () => {
    setFilterBy({
      priceMin: filterByPriceMin,
      priceMax: filterByPriceMax,
      yearMin: filterByYearMin,
      yearMax: filterByYearMax,
      kmMin: filterByKmMin,
      kmMax: filterByKmMax,
      category: filterByCategory,
      make: filterByMake,
      model: filterByModel,
      condition: filterByCondition,
      marketDiscount: filterByMarketDiscount,
      canceledStatus: filterByPublicationCanceled,
    });

    onHide(true);
  };

  return (
    <Form className="px-3">
      { showFilterByPrice &&

      <Form.Group className="m-1 w-100">
        <Form.Label>Precio</Form.Label>
        <Col className="m-1 p-1">
          <NumberFormat
            className="form-control"
            placeholder="Mínimo"
            decimalSeparator={","}
            thousandSeparator={"."}
            onValueChange={(v) => {
              const { value } = v;
              handleFilterByPriceMin({
                target: { value: value, id: "priceMin" },
              });
            }}
          />
        </Col>
        <Col className="m-1 p-1">
          <NumberFormat
            className="form-control"
            placeholder="Máximo"
            decimalSeparator={","}
            thousandSeparator={"."}
            onValueChange={(v) => {
              const { value } = v;
              handleFilterByPriceMax({
                target: { value: value, id: "priceMax" },
              });
            }}
          />
        </Col>
      </Form.Group>
    }

{ showFilterByYear &&
      <Form.Group>
        <Form.Label>Año</Form.Label>
        <Col className="m-1 p-1">
          <NumberFormat
            className="form-control"
            placeholder="Mínimo"
            decimalSeparator={","}
            thousandSeparator={"."}
            onValueChange={(v) => {
              const { value } = v;
              handleFilterByYearMin({
                target: { value: value, id: "yearMin" },
              });
            }}
          />
        </Col>
        <Col className="m-1 p-1">
          <NumberFormat
            className="form-control"
            placeholder="Máximo"
            decimalSeparator={","}
            thousandSeparator={"."}
            onValueChange={(v) => {
              const { value } = v;
              handleFilterByYearMax({
                target: { value: value, id: "yearMax" },
              });
            }}
          />
        </Col>
      </Form.Group>
  }

{ showFilterByCategory && 
      <Form.Group>
        <Form.Label>Categoria</Form.Label>
        <Form.Control
          as="select"
          id="vehicleCategorySelect"
          onChange={onChangeOptionCategory}
          className="ml-3"
        >
          <option selected disabled>
            Todos
          </option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
  }

  { showFilterByMake && 
      <Form.Group>
        <Form.Label>Marca</Form.Label>
        <Form.Control
          as="select"
          onChange={onChangeOptionMake}
          className="ml-3"
        >
          <option selected disabled>
            Todos
          </option>
          {makes.map((make) => (
            <option key={make.id} value={make.id}>
              {make.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
}

{ showFilterByModel && 
      <Form.Group>
        <Form.Label>Modelo</Form.Label>
        <Form.Control
          as="select"
          className="ml-3"
          onChange={handleFilterByModel}
        >
          <option selected disable>
            {" "}
            Todos
          </option>
          {models.map((model) => (
            <option key={model.id} value={model.id}>
              {model.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
  }

{ showFilterByCondition && 
<div>
      <Form.Label for="vehicle-status">Condición</Form.Label>
      <div id="vehicle-status-check" className="ml-3">
        <Form.Check
          inline
          label="Nuevo"
          value="Nuevo"
          name="vehicle-status-radio"
          type="radio"
          id="inline-radio-1"
          onChange={handleFilterByCondition}
        />
        <Form.Check
          inline
          label="Usado"
          value="Usado"
          name="vehicle-status-radio"
          type="radio"
          id="inline-radio-2"
          onChange={handleFilterByCondition}
        />
      </div>
      </div> 
  }
  <br />
      { showFilterByMarketDiscount && 
      <div id="vehicle-discount" className="ml-3">
        <Form.Check
          inline
          label="Precio bajo info"
          name="vehicle-discount"
          value="true"
          type="radio"
          id="inline-radio-3"
          onChange={handleFilterByMarketDiscount}
        />
        { isAdmin() && (
        <Form.Check
          inline
          label="Publicaciones canceladas"
          name="publicationCanceled"
          value="true"
          type="radio"
          id="inline-radio-4"
          onChange={handleFilterPublicationCanceled}
        />
        )}
      </div>
  }
      <br />
  { showFilterByKm && 
      <Form.Group>
        <Form.Label>Kilometros</Form.Label>
        <Col className="m-1 p-1">
          <NumberFormat
            className="form-control"
            placeholder="Mínimo"
            decimalSeparator={","}
            thousandSeparator={"."}
            onValueChange={(v) => {
              const { value } = v;
              handleFilterByKmMin({ target: { value: value, id: "kmMin" } });
            }}
          />
        </Col>
        <Col className="m-1 p-1">
          <NumberFormat
            className="form-control"
            placeholder="Máximo"
            decimalSeparator={","}
            thousandSeparator={"."}
            onValueChange={(v) => {
              const { value } = v;
              handleFilterByKmMax({ target: { value: value, id: "kmMax" } });
            }}
          />
        </Col>
      </Form.Group>
  }
      <Form.Group>
        <Button onClick={onFilter}>Filtrar</Button>
      </Form.Group>
    </Form>
  );
}
