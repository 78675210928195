import React from "react";
import {
  FacebookIcon,
  WpIcon,
  EnvelopeIcon,
} from "utils/styled-components/icons";

export default function TextFooter() {
  return (
    <div className="footer__line-bottom">
      <span className="">
        @{new Date().getFullYear()} AutosMisiones.com Lo tiene todo!
      </span>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ gap: "8px" }}
      >
        <div className="footer__line-bottom__social">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/autosmisiones"
          >
            <FacebookIcon
              className="mx-1 cursor-pointer"
              color="white"
              size={9}
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/5493765050405"
          >
            <WpIcon className="mx-1 cursor-pointer" color="white" size={13} />
          </a>
          <a rel="noreferrer" href="mailto:some@gmail.com">
            <EnvelopeIcon
              className="mx-1 cursor-pointer"
              color="white"
              size={13}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
