export function transformPublicationResponse(data, rest) {
  return Object.values(data).map((relation, index) => {
    return {
      createdAt: relation.createdat,
      currencyType: relation.currencytype,
      customId: relation.customid,
      customMake: relation.custommake,
      customModel: relation.custommodel,
      customVersion: relation.customversion,
      full_count: relation.full_count,
      id: relation.id,
      imageUrl: relation.url,
      index,
      isFavorite: !!relation.favorite,
      km: relation.km,
      makeId: relation.makeid,
      marketDiscount: relation.marketdiscount,
      modelId: relation.modelid,
      modifiedAt: relation.modifiedat,
      personId: relation.personid,
      price: relation.price,
      slugUrl: relation.slugurl,
      statusId: relation.statusid,
      vehicleCategoryId: relation.vehiclecategoryid,
      vehicleMake: relation.makename,
      vehicleModel: relation.modelname,
      vehicleVersion: relation.versionname,
      versionId: relation.versionid,
      year: relation.year,
      ...rest,
    };
  });
}
