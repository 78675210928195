/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import { useRoute } from "wouter";
import NavBar from "../layouts/NavBar";
import AutoCar from "../components/AutoCar";
import {
  getDetailSlug,
  getRelationsPrice,
  getRelationsCars,
  getRelationsYear,
} from "../services/publication";
import { TABLE_ATTRIBUTES_I, STATUS_PUBLICATION } from "../../src/utils/constants";
import { getUsDolarCurrency } from "../services/externalServices";
import PublicationListComponent from "../components/PublicationListComponent";
import FooterPage from "components/Footer/FooterPage";
import Skeleton from "react-loading-skeleton";
import Error404ComponentPage from "components/Error404ComponentPage";
import Context from "context/UserContext";

export default function VehicleDetailPage() {
  const [, params] = useRoute("/vehicle/detail/:slug");
  const [loading, setLoading] = useState(true);
  const [vehiclesDetail, setVehiclesDetail] = useState(null);
  const [loadDetails, setLoadDetails] = useState(true);
  const [details, setDetails] = useState([]);
  const [relationsCar, setRelationsCar] = useState([]);
  const [relationsPrice, setRelationsPrice] = useState([]);
  const [relationsYear, setRelationsYear] = useState([]);
  const { isAdmin } = useContext(Context);

  const [loads, setLoads] = useState({
    relations: true,
    price: true,
    year: true,
  });
  
  const [usData, setUsData] = useState({
    venta: "",
  });

  useEffect(() => {
    console.log("--A:" + !isAdmin() + "--B:" + isAdmin());
    async function getUsDolar() {
      const result = await getUsDolarCurrency();
      setUsData((usData) => {
        return {
          ...usData,
          venta: result.data?.blue.value_sell,
        };
      });
    }

    getUsDolar();
  }, []);

  useEffect(() => {
    async function loadDetail() {
      setLoading((prev) => true);
      try {     
      const response = await getDetailSlug(params.slug);
        if (response != null && response.status === 200) {
          setVehiclesDetail(response.data.response);
          setLoadDetails((prev) => false);
          setLoading((prev) => false);
        }
      } catch (error) {

        console.error("Error al cargar el detalle:", error);

      }
    }    
    loadDetail();
    window.scrollTo(0, 0);
  }, [params.slug]);

  useEffect(() => {
    if (vehiclesDetail) {
      let filteredList = [];

      Object.keys(TABLE_ATTRIBUTES_I).map((key) => {
        if (vehiclesDetail[TABLE_ATTRIBUTES_I[key].attrName]) {
          const attrAux =
            TABLE_ATTRIBUTES_I[key].nameEs.charAt(0).toUpperCase() +
            TABLE_ATTRIBUTES_I[key].nameEs.slice(1);

          let value;
          switch (typeof vehiclesDetail[TABLE_ATTRIBUTES_I[key].attrName]) {
            case "number":
              value = vehiclesDetail[TABLE_ATTRIBUTES_I[key].attrName]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              break;
            case "boolean":
              value = vehiclesDetail[TABLE_ATTRIBUTES_I[key].attrName]
                ? "Si"
                : "No";
              break;
            default:
              value = vehiclesDetail[TABLE_ATTRIBUTES_I[key].attrName];
              break;
          }
          filteredList.push({ key: attrAux, value });
        }
      });
      setDetails(filteredList);
    }
  }, [vehiclesDetail]);

  useEffect(() => {
    if (vehiclesDetail) {
      getRelations();
      getPublicationPrice();
      getPublicationYear();
    }
  }, [vehiclesDetail]);
  const setPublicationsObject = (response) => {
    return Object.values(response).map((relation) => {
      return {
        id: relation.id,
        currencyType: relation.currencytype,
        km: relation.km,
        year: relation.yearmodel,
        price: relation.price,
        vehicleVersion: relation.version_name,
        vehicleModel: relation.modelname,
        vehicleMake: relation.makename,
        imageUrl: relation.url,
        slugUrl: relation.slugurl,
        createdAt: relation.createdat,
        modifiedAt: relation.modifiedat,
      };
    });
  };
  async function getPublicationYear() {
    setLoads((loads) => ({ ...loads, year: true }));
    const publicationId = vehiclesDetail?.id;
    const yearModel = vehiclesDetail?.year;
    const category = vehiclesDetail?.vehicleCategoryId;
    if (
      (vehiclesDetail?.year &&
        vehiclesDetail?.vehiculoVersion?.vehicleModel?.vehicleMake.id) ||
      (vehiclesDetail?.year && vehiclesDetail.price === 0)
    ) {
      let response = await getRelationsYear(yearModel, category, publicationId);
      if (response?.status === 200) {
        const objectCars = Object.values(response.data.response).map(
          (car, index) => {
            return {
              id: car.id,
              currencyType: car.currencytype,
              price: car.price,
              km: car.km,
              versionId: car.versionid,
              vehicleVersion: car.vehicleversion,
              modelId: car.modelid,
              vehicleModel: car.vehiclemodel,
              makeId: car.makeid,
              vehicleMake: car.vehiclemake,
              customId: car.customid,
              customMake: car.custommake,
              customModel: car.custommodel,
              customVersion: car.customversion,
              year: car.yearmodel,
              imageUrl: car.url,
              slugUrl: car.slugurl,
              isFavorite: !!car.favorite,
              createdAt: car.createdat,
              modifiedAt: car.modifiedat,
              index,
            };
          }
        );
        response = setPublicationsObject(response.data.response);
        setRelationsYear(objectCars);
      }
    }
    setLoads((loads) => ({ ...loads, year: false }));
  }
  async function getRelations() {
    setLoads((loads) => ({ ...loads, relations: true }));
    const category = vehiclesDetail?.vehicleCategoryId;
    const make = vehiclesDetail?.vehiculoVersion?.vehicleModel?.vehicleMake.id;
    const idPublication = vehiclesDetail?.id;
    if (make && category) {
      let response = await getRelationsCars(category, make, idPublication);
      if (response?.status === 200) {
        const objectCars = Object.values(response.data.response).map(
          (car, index) => {
            return {
              id: car.id,
              currencyType: car.currencytype,
              price: car.price,
              km: car.km,
              versionId: car.versionid,
              vehicleVersion: car.vehicleversion,
              modelId: car.modelid,
              vehicleModel: car.vehiclemodel,
              makeId: car.makeid,
              vehicleMake: car.vehiclemake,
              customId: car.customid,
              customMake: car.custommake,
              customModel: car.custommodel,
              customVersion: car.customversion,
              year: car.yearmodel,
              imageUrl: car.url,
              slugUrl: car.slugurl,
              isFavorite: !!car.favorite,
              createdAt: car.createdat,
              modifiedAt: car.modifiedat,
              index,
            };
          }
        );
        response = setPublicationsObject(response.data.response);
        setRelationsCar(objectCars);
      }
    }
    setLoads((loads) => ({ ...loads, relations: false }));
  }
  async function getPublicationPrice() {
    setLoads((loads) => ({ ...loads, price: true }));
    const publicationId = vehiclesDetail?.id;
    const priceVehicle =
      vehiclesDetail?.currencyType === "ARS$" ||
      vehiclesDetail?.currencyType === "$" ||
      !vehiclesDetail?.currencyType
        ? vehiclesDetail?.price
        : vehiclesDetail?.price * usData.venta;
    const category = vehiclesDetail?.vehicleCategoryId;
    if (vehiclesDetail?.price) {
      let response = await getRelationsPrice(
        priceVehicle,
        category,
        publicationId
      );
      if (response?.status === 200) {
        const objectCars = Object.values(response.data.response).map(
          (car, index) => {
            return {
              id: car.id,
              currencyType: car.currencytype,
              price: car.price,
              km: car.km,
              versionId: car.versionid,
              vehicleVersion: car.vehicleversion,
              modelId: car.modelid,
              vehicleModel: car.vehiclemodel,
              makeId: car.makeid,
              vehicleMake: car.vehiclemake,
              customId: car.customid,
              customMake: car.custommake,
              customModel: car.custommodel,
              customVersion: car.customversion,
              year: car.yearmodel,
              imageUrl: car.url,
              slugUrl: car.slugurl,
              isFavorite: !!car.favorite,
              createdAt: car.createdat,
              modifiedAt: car.modifiedat,
              index,
            };
          }
        );
        setRelationsPrice(objectCars);
      }
    }
    setLoads((loads) => ({ ...loads, price: false }));
  }
  
  return (
    <>
      <NavBar></NavBar>
      { loading ? (
        <Skeleton
          containerClassName="skeleton-home mb-3"
          height={250}
          width={"100%"}
          count={12}
        />
      ) : (vehiclesDetail === null ) 
        || 
          (vehiclesDetail.statusId === STATUS_PUBLICATION.CANCELED && !isAdmin()) 
       ? 
          (
            <Error404ComponentPage />
          )
        : 
       (
        <Container fluid className="ConcessionaireImage m-0 py-2 px-3">
          {
            <div className="mt-1">
              <AutoCar
                className=""
                cardVehicle={vehiclesDetail && vehiclesDetail}
                showMore={details && details}
                setVehiclesDetail={setVehiclesDetail}
                loadDetails={loadDetails}
              />
            </div>
          }
          <hr className="mt-4 mb-0" />
          <PublicationListComponent
            title="Vehiculos que podrían interesarte"
            publications={relationsCar}
            editBtn={false}
            disabledStatusButton={false}
            load={loads.relations}
            key="card-detail-cars"
            background="cars"
            setPublicationList={setRelationsCar}
          />
          <PublicationListComponent
            title="Quizas te interesen estos vehículos a un precio aproximado al que estas mirando"
            publications={
              vehiclesDetail && vehiclesDetail?.price ? relationsPrice : []
            }
            editBtn={false}
            alignRight
            disabledStatusButton={false}
            load={loads.price}
            key="card-detail-price"
            background="price"
            setPublicationList={setRelationsPrice}
          />
          <PublicationListComponent
            title="Tambien te pueden interesar estos vehículos del mismo año."
            publications={relationsYear ?? []}
            editBtn={false}
            disabledStatusButton={false}
            load={loads.year}
            key="card-detail-year"
            background="year"
            setPublicationList={setRelationsYear}
          />
          <FooterPage/>
      </Container>
      )}
    </>
  );
}
