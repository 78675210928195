import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  InputGroup,
  Table,
} from "react-bootstrap";
import swal from "sweetalert";
import Context from "context/OrderByContext";
import FilterByContext from "context/FilterByContext";
import { getPublicationHome, postPublicationBulkEdit } from "services/publication";
import { getLocalStorage } from "utils/localStorage";
import { GetMakeModel, GetVersion, getTimeFromNow } from "utils/publicMethods";
import { CurrencyFormatter, formatNumberWithCommas } from "utils/helps";
import Pagination from "./Publications/Pagination";
import { transformPublicationResponse } from "utils";
import PublicationsFilter from "../../components/Publication/Publications/filter";

export default function BulkUpdateComponents() {
  const [publications, setPublications] = useState([]);
  const [totalPublication, setTotalPublication] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const { filterBy } = useContext(FilterByContext);
  const { orderBy } = useContext(Context);

  // If there are pages with all rows selected we need to save the number of the page in this array state
  const [selectAllPerPage, setSelectAllPerPage] = useState([]);
  const percentageAmount = useRef(0);
  const roundAmount = useRef();

  const publicationPerPage = 20;
  const firstPage = 1;
  const withPriceOnly= true;

  useEffect(()=>{
    swal({
      title: "Atencion",
      text: "Esta seccion no recupera tus publicaciones sin precio, en esos casos deberas actualizar el precio ingresando al vehiculo en particular.",
      icon: "warning",
      button: "OK",
    });
    return;
  } ,[]);

  useEffect(() =>  getPublications(firstPage), [orderBy, filterBy]);

  useEffect(() => setNewPrice(publications), [selectedRows]);
  let userPublication = "";

  const getPublications = async (page) => {
    userPublication = getLocalStorage("userId");

    const skip = ((!page ? currentPage : page) - 1) * publicationPerPage;
    const queries = { orderBy, filterBy, skip, userPublication, withPriceOnly};    
  
    const response = await getPublicationHome(queries);
    if (response?.status === 200) {
      const parsedPublications = transformPublicationResponse(
        response.data.response,
        { newPrice: 0 }
      );
      setTotalPublication(parsedPublications[0]?.full_count);
      setPublications(parsedPublications);
      setCurrentPage(!page ? 1 : page ?? currentPage);

      setNewPrice(parsedPublications);
    }
  };

  const handlePagination = async (page) => {
    await getPublications(page);
    window.scrollTo(0, 0);
  };

  const setNewPrice = (parsedPublications) => {
    const percentage = parseFloat(percentageAmount.current.value) || null;

    if (!percentage) return;

    const updatedPublications = parsedPublications.map((publication) => {
      if (selectedRows.includes(publication.id)) {
        const initialPrice = publication.price || 0;
        let newPrice = initialPrice + initialPrice * (percentage / 100);
        if (roundAmount.current.checked) {
          newPrice = Math.floor(newPrice / 1000) * 1000;
        }
        return { ...publication, newPrice };
      }

      return { ...publication, newPrice: 0 };
    });

    setPublications(updatedPublications);
  };

  const handleRowCheckboxChange = (event, publicationId, page) => {
    const checked = event.target.checked;

    if (!checked && selectAllPerPage.includes(page)) {
      setSelectAllPerPage(selectAllPerPage.filter((item) => item !== page));
    }

    if (checked && !selectedRows.includes(publicationId)) {
      setSelectedRows([...selectedRows, publicationId]);
    } else {
      const updatedSelectedRows = selectedRows.filter(
        (selectedRow) => selectedRow !== publicationId
      );
      setSelectedRows(updatedSelectedRows);
    }
  };

  const formatCurrencyAndValue = (currency, value) => {
    return `${currency} ${CurrencyFormatter.format(value)}`;
  };

  const handleUpdateButtonClick = () => {
    if (selectedRows.length === 0) {
      swal({
        title: "Error",
        text: "Debe seleccionar al menos una publicación para continuar.",
        icon: "error",
        button: "OK",
      });
      return;
    }
    swal({
      title: "Actualizar publicaciones",
      text: `${selectedRows.length === 1 ? 'Se va a actualizar un solo elemento. ¿Esta seguro?' : 'Esta a punto de actualizar varias publicaciones. ¿Esta seguro?'}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willProceed) => {
      if (willProceed) {
        const publicationIds = selectedRows.toString();
        const percentage = parseInt(percentageAmount.current.value);
        const roundResult = roundAmount.current.checked || false;
        await postPublicationBulkEdit(publicationIds, percentage, roundResult);
        percentageAmount.current.value = "";
        resetSelectStates();
      } else {
        swal.close();
      }
    });
  };

  const handleSetSelectedAllPerPage = (event, page) => {
    const checked = event.target.checked;
    const publicationIds = publications.map((publication) => publication.id);

    if (checked && !selectAllPerPage.includes(page)) {
      const updatedSelectAllPerPage = [...selectAllPerPage, page];
      setSelectAllPerPage(updatedSelectAllPerPage);
      setSelectedRows([...selectedRows, ...publicationIds]);
    } else if (!checked && selectAllPerPage.includes(page)) {
      const updatedSelectAllPerPage = selectAllPerPage.filter(
        (p) => p !== page
      );
      setSelectAllPerPage(updatedSelectAllPerPage);
      const updatedSelectedRows = selectedRows.filter(
        (item) => !publicationIds.includes(item)
      );
      setSelectedRows(updatedSelectedRows);
    }
  };

  const isThisPageSelected = (page) => selectAllPerPage.includes(page);

  const resetSelectStates = () => {
    setSelectedRows([]);
    setSelectAllPerPage([]);
  };

  return (
    <>
      <Container className="my-4">
        <h5>Actualización de mis publicaciones</h5>
        <Form className="my-4">
          <Row>
            <Col xs={6}>
              <Form.Group controlId="numericInput">
                <InputGroup>
                  <Form.Control
                    placeholder="Porcentaje a actualizar"
                    aria-label="priceToUpdate"
                    type="number"
                    onChange={() => setNewPrice(publications)}
                    ref={percentageAmount}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Form.Group>
        
            </Col>
            <Col xs={3}>
              <Form.Check
                type="checkbox"
                label="Redondear resultado"
                className="mt-2"
                onChange={() => setNewPrice(publications)}
                ref={roundAmount}
              />
            </Col>
            <Col xs={3} className="d-flex justify-content-end">
              <Button
                className="btn btn-primary"
                onClick={handleUpdateButtonClick}
              >
                Actualizar
              </Button>
            </Col>
          </Row>
        </Form>
        <div className="filter-search-order mt-5">
            <PublicationsFilter
              showFilterByPrice={false}
              showFilterByModel={false}
              showFilterByVersion={false}
              showFilterByMarketDiscount={false}
              showFilterByKm={false}
            />
          </div>
        <Table className="text-left" striped bordered hover variant="dark">
          <thead>
            <tr
              className="text-align-center custom-row"
              style={{ height: "10px", margin: "0px" }}
            >
              {isThisPageSelected(currentPage) ? (
                <td className="text-warning align-middle" colSpan={6}>
                  Se han seleccionado las {publications.length} publicaciones de
                  esta página
                </td>
              ) : (
                <td colSpan={6} className="align-middle text-danger">
                  Debe seleccionar los vehículos que desea actualizar
                </td>
              )}
              <td className="align-middle">
                <Pagination
                  publicationPerPage={publicationPerPage}
                  totalPublication={totalPublication}
                  paginate={handlePagination}
                  currentPage={currentPage}
                />
              </td>
            </tr>
          </thead>
          <thead>
            <tr>
              <th width="30">
                <input
                  checked={isThisPageSelected(currentPage)}
                  onChange={(event) =>
                    handleSetSelectedAllPerPage(event, currentPage)
                  }
                  type="checkbox"
                />
              </th>
              <th>Vehículo</th>
              <th>Año</th>
              <th>Km</th>
              <th>Última actualización</th>
              <th>Precio actual</th>
              <th>Precio actualizado</th>
            </tr>
          </thead>
          <tbody>
            {publications.map((publication) => (
              <tr key={publication.id}>
                <td>
                  <input
                    onChange={(event) =>
                      handleRowCheckboxChange(
                        event,
                        publication.id,
                        currentPage
                      )
                    }
                    type="checkbox"
                    checked={selectedRows.includes(publication.id)}
                  />
                </td>
                <td>
                  <a
                    href={`/vehicle/detail/${publication.slugUrl}`}
                    target="blank"
                  >
                    <GetMakeModel data={publication} />
                    &nbsp;
                    <GetVersion data={publication} />
                  </a>
                </td>
                <td>{publication.year}</td>
                <td>{formatNumberWithCommas(publication.km)}</td>
                <td>{getTimeFromNow(publication.modifiedAt)}</td>
                <td>
                  {formatCurrencyAndValue(
                    publication.currencyType,
                    publication.price
                  )}
                </td>
                <td>
                  {publication.newPrice !== 0 &&
                  publication.newPrice !== publication.price
                    ? formatCurrencyAndValue(
                        publication.currencyType,
                        publication.newPrice
                      )
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={7}>
                <Pagination
                  publicationPerPage={publicationPerPage}
                  totalPublication={totalPublication}
                  paginate={handlePagination}
                  currentPage={currentPage}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </Container>
    </>
  );
}
