import React, { useEffect, useState } from "react";
import { getCountPublicationsByUser } from "services/publication";
import { getUserImages } from "services/userAccount";
import { WpIcon } from "utils/styled-components/icons";
import { useRoute } from "wouter";

export default function CardConcessionaireData({ userData }) {
  const [selectedFile, setSelectedFile] = useState([]);
  const [, params] = useRoute("/concessionaire/:id");
  const [publicationCount, setPublicationCount] = useState("1");

  useEffect(() => {
    getImages();
    countPublicationByUser();
  }, []);

  const getImages = async () => {
    const imagesData = await getUserImages({ personId: params.id });
    setSelectedFile(() => {
      return imagesData.data.map((file, index) => {
        return {
          index: index,
          imagePreviewUrl: file.url,
          name: file.imageType,
          imageType: file.imageType,
          alreadyExists: true,
          delete: false,
        };
      });
    });
  };

  const countPublicationByUser = async () => {
    let personId = params.id;
    getCountPublicationsByUser(personId);

    let response = await getCountPublicationsByUser(personId);
    setPublicationCount(response.data.response[0].count);
  };

  const getCoverImage = () => {
    const coverImage = selectedFile.find((file) => file.imageType === "cover");
    return coverImage?.imagePreviewUrl;
  };

  const getLogoImage = () => {
    const logo = selectedFile.find((file) => file.imageType === "logo");
    return logo?.imagePreviewUrl;
  };

  const phoneNumber = userData?.phones?.[0]?.phone ?? '';

  return (
    <div className="wrap-info_home">
      <div
        className="banner animated fadeIn"
        style={
          selectedFile
            ? { backgroundImage: `url(${getCoverImage()})` }
            : { backgroundImage: `url(/img/default_banner.png)` }
        }
      ></div>
      <div className="body">
        <div className="_info d-flex">
          <div className="logo-store rounded-circle">
            {selectedFile ? (
              <img src={getLogoImage()} alt="logo auto misiones" />
            ) : (
              <span className="letter montserrat animated flipInX">
                {userData?.firstName?.split("")[0]}
              </span>
            )}
          </div>
          <div className="_text mt-2">
            {userData ? (
              <h2 className="mb-2">
                {userData?.firstName} {userData?.lastName}
              </h2>
            ) : (
              "Nombre de la compañía"
            )}
            <p className="mb-0">{userData.locationStreet}</p>
            <p className="mb-0 mt-3 text-dark">
              <b>Publicaciónes:</b>
              {publicationCount}
            </p>
          </div>
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-between text-left align-content-center mb-0 mt-3 gap flex-wrap">
          <p
            className="mb-0"
            style={{ flex: "1 300px", maxWidth: "fit-content" }}
          >
            <span className="fw-500">Horario de atención: </span>
            <blockquote>{userData?.openingHours}</blockquote>
          </p>
          <a
            className="publicationshome-wrapper__right__wapp-btn my-0 text-white"
            href={`https://wa.me/${phoneNumber}`}
            style={{ maxWidth: "170px", textDecoration: "none" }}
          >
            <WpIcon className="mr-1" color="white" size={20} />
            <span>Contactanos!</span>
          </a>
        </div>
      </div>
    </div>
  );
}
