import { Link } from "wouter";
import { Card, Button } from "react-bootstrap";

import { ExternalLinkAltIcon } from "../../utils/styled-components/icons";

export default function AllPublications() {
  return (
    <Card className="shadow-lg mb-5 bg-white rounded">
      <Card.Body className="p-3">
        <Card.Title>
          Todas las publicaciones
          <Link href="/dashboard/allPublicationsPage">
            <Button
              variant="outline-primary"
              className="btn btn-sm float-right"
            >
              <ExternalLinkAltIcon title="Ver listado" />
            </Button>
          </Link>
        </Card.Title>
      </Card.Body>
    </Card>
  );
}
