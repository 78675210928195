import requests from "../utils/requests";

export const getCriptoCurrency = async () => {
  const url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd`;
  const response = await requests(url, "GET");
  return response;
};

export const getUsDolarCurrency = async () => {
  const url = `https://api.bluelytics.com.ar/v2/latest`;
  const response = await requests(url, "GET");
  return response;
};
