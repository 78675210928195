import requests from "../utils/requests";

export const getCategories = async () => {
  const url = `${process.env.REACT_APP_API_URL}/api/vehicleCategories?filter[order]=name%20ASC`;
  return await requests(url, "GET");
};

export const getMakesByCategoryId = async ({ vehicleCategoryId }) => {
  if (!vehicleCategoryId || isNaN(vehicleCategoryId)) return;
  const url = `${process.env.REACT_APP_API_URL}/api/vehicleMakes/getMakeByCategoryId?categoryId=${vehicleCategoryId}`;
  return await requests(url, "GET");
};
/**
 
*/
export const getMakes = async () => {
  const url = `${process.env.REACT_APP_API_URL}/api/vehicleMakes?filter[order]=name%20ASC`;
  return await requests(url, "GET");
};

export const getMake = async ({ id }) => {
  if (!id || isNaN(id)) return;
  const url = `${process.env.REACT_APP_API_URL}/api/vehicleMakes/${id}`;
  return await requests(url, "GET");
};

export const getModelsByMakeId = async ({ vehicleMakeId }) => {
  if (!vehicleMakeId || isNaN(vehicleMakeId)) return;
  const url = `${process.env.REACT_APP_API_URL}/api/vehicleMakes/${vehicleMakeId}/models`;
  return await requests(url, "GET");
};

export const getModelsByMakeIdAndCategoryId = async ({
  vehicleCategoryId,
  vehicleMakeId,
}) => {
  if (
    !vehicleMakeId ||
    !vehicleMakeId ||
    isNaN(vehicleMakeId) ||
    isNaN(vehicleMakeId)
  )
    return;
  const filter = `filter={"where":{"and":[{"vehicleMakeId":${vehicleMakeId}},{"vehicleCategoryId":${vehicleCategoryId}}]}}`;
  const url = `${process.env.REACT_APP_API_URL}/api/vehicleMakes/${vehicleMakeId}/models?${filter}`;
  return await requests(url, "GET");
};

export const getVersionsByModelId = async ({ vehicleModelId }) => {
  if (!vehicleModelId || isNaN(vehicleModelId)) return;
  const url = `${process.env.REACT_APP_API_URL}/api/vehicleModels/${vehicleModelId}/versions?filter[order]=name%20ASC`;
  return await requests(url, "GET");
};
