import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

import forgetPasswordImage from "../assets/images/forget-password.svg";

export default function ConfirmEmailPage() {
  const handleReturn = () => {
    window.location.href = "/signin";
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h1>Su correo electrónico se ha confirmado</h1>
          <div className="text-center">
            <img
              src={forgetPasswordImage}
              className="img-fluid w-25"
              alt="mail box"
            />
          </div>
          <div className="mt-4">
            <Button variant="outline-secondary" onClick={() => handleReturn()}>
              Regresar al inicio de sesión
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
