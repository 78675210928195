import { Col, Container, Row, InputGroup, Nav, Tooltip } from "react-bootstrap";
import { EnvelopeIcon, KeyIcon } from "../utils/styled-components/icons";
import { signUp } from "../services/userAccount";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useLocation } from "wouter";
import {
  LOGINBUTTON,
  FORMCTRL,
  IMG,
  TEXTAM,
  INPUTCTRL,
} from "./../components/SignInOutStyles";
import { } from 'react-bootstrap';

export default function SignUpPage() {
  const [, setLocation] = useLocation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    async function handleLoading() {
      if (loading && !swal.getState().isOpen) {
        swal({
          title: "Cargando...",
          buttons: false,
        });
      } else if (swal.getState().isOpen) {
        swal.close();
      }
    }
    handleLoading();
  }, [loading]);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await signUp(formData);
    if (response.error) {
      swal({
        title: "Oops",
        icon: "error",
        text: response.error.message,
      });
    } else {
      setLoading(false);
      swal({
        title: "¡Cuenta creada exitosamente!",
        text: "Por favor, diríjase a su correo para activar su cuenta.",
        icon: "success",
        button: "Iniciar sesión",
      }).then(() => setLocation("/signin"));
    }
  };

  return (
    <Container
      fluid
      className="signUpImage d-flex justify-content-center align-items-center"
    >
      <Row>
        <Col sm="12" md="6" className="order-1 order-sm-0">
          <form onSubmit={handleSubmit} className="">
            <Col className="signUpPageForm">
              <InputGroup className="mb-3">
                <FORMCTRL
                  placeholder="Nombre"
                  aria-label="Nombre"
                  name="firstName"
                  onChange={handleInputChange}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <FORMCTRL
                  placeholder="Apellido (opcional)"
                  aria-label="Apellido"
                  name="lastName"
                  onChange={handleInputChange}
                />
              </InputGroup>
              <InputGroup className="mb-3 form-horizontal">
                <InputGroup.Prepend>
                  <IMG className="d-flex justify-content-center align-items-center">
                    <EnvelopeIcon className="iconAM" size={17} />
                  </IMG>
                </InputGroup.Prepend>
                <INPUTCTRL
                  placeholder="Correo electrónico"
                  aria-label="Email"
                  type="email"
                  name="email"
                  onChange={handleInputChange}
                />
              </InputGroup>
              <InputGroup className="mb-3 align-items-center">
                <InputGroup.Prepend>
                  <IMG className="d-flex justify-content-center align-items-center">
                    <KeyIcon className="iconAM" size={17} />
                  </IMG>
                </InputGroup.Prepend>
                <INPUTCTRL
                  placeholder="Contraseña"
                  aria-label="Password"
                  type="password"
                  name="password"
                  onChange={handleInputChange}
                />
              </InputGroup>

              <Row>
                <InputGroup className="d-flex justify-content-center align-items-center m-1">
                  <LOGINBUTTON
                    type="button"
                    className="px-3 m-1"
                    onClick={() => setLocation("/signin")}
                  >
                    Tengo una cuenta
                  </LOGINBUTTON>
                  <LOGINBUTTON type="submit" className="px-3 m-1">
                    Crear una cuenta
                  </LOGINBUTTON>
                </InputGroup>
              </Row>

              {/* <small>
                <TEXTOPTION>TAMBIEN PODES</TEXTOPTION>
              </small>

              <Col className="justify-content-center">
                <Nav.Link href="/signup">
                  <FCBOOK>
                    <FacebookIcon className="mr-2" size={20} />
                    Registrate con FACEBOOK
                  </FCBOOK>
                </Nav.Link>
              </Col>

              <Col className="justify-content-center d-flex-1">
                <Nav.Link href="/publication">
                  <GOOGL>
                    <GoogleIcon className="mr-2" size={20} />
                    Registrate con GOOGLE
                  </GOOGL>
                </Nav.Link>
              </Col> */}
            </Col>
          </form>
        </Col>

        <Col
          sm="12"
          md="6"
          className="col-md-offset-1
          order-0 order-sm-1"
        >
          <Nav.Link href="/">
            <img
              className="imgLogo"
              src="img/logoAm.png"
              alt="logo auto misiones"
              title="Ir al inicio"
            />
            
            <span className="d-none d-md-block">
              <TEXTAM>Autos Misiones</TEXTAM>
            </span>
          </Nav.Link>
        </Col>
      </Row>
    </Container>
  );
}
