import React, { useState } from "react";
const FilterByContext = React.createContext({});

export function FilterByContextProvider({ children }) {
  const [filterBy, setFilterBy] = useState({});

  return (
    <FilterByContext.Provider value={{ filterBy, setFilterBy }}>
      {children}
    </FilterByContext.Provider>
  );
}

export default FilterByContext;
