/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import FooterPage from "../components/Footer/FooterPage";
import NavBar from "layouts/NavBar";
import CardConcessionaireData from "components/Concessionaire/CardConcessionaireData";
import { useRoute } from "wouter";
import { getPublicationHome } from "services/publication";
import InputSearch from "components/InputSearch";
import Skeleton from "react-loading-skeleton";
import { TEXTALERT } from "components/Publication/styles";
import PublicationCard from "components/Publication/PublicationCard";
import Pagination from "components/Publication/Publications/Pagination";
import { getPerson } from "services/userAccount";
import ConcessionaireList from "components/Concessionaire/ConcessionaireList";

export default function ConcessionairePage() {
  const [, params] = useRoute("/concessionaire/:id");
  const [myPublicationsConcess, setMyPublicationsConcess] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPublication, setTotalPublication] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [time, setTime] = useState(null);
  const [fromSearch, setFs] = useState("");
  const [userData, setUserData] = useState(false);
  const publicationPerPage = 20;

  let userPublication = "";

  useEffect(() => {
    getUserData();
    getPublications();
  }, [params.id]);

  const getUserData = async () => {
    const uData = await getPerson({ id: params.id });
    setUserData(uData.data);
  };

  const getPublications = async (page, title = null) => {
    userPublication = params.id;
    setLoading((prev) => true);
    const skip = ((!page ? currentPage : page) - 1) * publicationPerPage;
    let queries = { skip };

    if (userPublication) queries.userPublication = userPublication;
    if (title) queries.title = title;
    const response = await getPublicationHome(queries);
    if (response?.status === 200) {
      const objectCars = Object.values(response.data.response).map(
        (relation, index) => {
          return {
            id: relation.id,
            personId: relation.personId,
            currencyType: relation.currencytype,
            price: relation.price,
            km: relation.km,
            year: relation.year,
            vehicleCategoryId: relation.vehiclecategoryid,
            versionId: relation.versionid,
            vehicleVersion: relation.versionname,
            modelId: relation.modelid,
            vehicleModel: relation.modelname,
            makeId: relation.makeid,
            vehicleMake: relation.makename,
            customId: relation.customid,
            customMake: relation.custommake,
            customModel: relation.custommodel,
            customVersion: relation.customversion,
            imageUrl: relation.url,
            slugUrl: relation.slugurl,
            isFavorite: !!relation.favorite,
            full_count: relation.full_count,
            createdAt: relation.createdat,
            index,
          };
        }
      );
      try {
        setTotalPublication(objectCars[0]?.full_count || 0);
        setMyPublicationsConcess(objectCars);
        setCurrentPage(!page ? 1 : page ?? currentPage);
      } catch (err) {
        console.log(err);
      }
      setLoading((prev) => false);
    }
  };
  const handlePagination = async (page) => {
    if (page === currentPage) return;
    if (fromSearch) {
      alert(page);
      set(fromSearch, page);
    } else {
      await getPublications(page);
    }
  };
  const set = async (val, page) => {
    if (val === "") {
      setLoading(true);
      setFs(false);
      await setCurrentPage((prev) => 1);
      await getPublications(1);
      setLoading(false);
      setIsTyping(false);
      return;
    } else {
      setFs((prev) => val);
      await setCurrentPage((prev) => page ?? 1);
      await getPublications(page, val);
      setIsTyping((prev) => false);
    }
  };
  const handleSearch = (val) => {
    setIsTyping((prev) => true);
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        set(val, null);
      }, 500)
    );
  };

  return (
    <>
      <NavBar />
      <CardConcessionaireData
        userPublication={userPublication}
        userData={userData}
      />
      <div className="w-100 px-5">
        <div className="filter-search-order mt-5">
          <InputSearch
            name="searchPublications"
            handleSearch={(e) => handleSearch(e.target.value)}
            placeholder="Buscar publicaciones"
            isTyping={isTyping}
            classes="shadow-sm"
          />
        </div>
        {loading ? (
          <Skeleton
            containerClassName="skeleton-home mb-3"
            height={250}
            width={"100%"}
            count={12}
          />
        ) : myPublicationsConcess.length <= 0 ? (
          <TEXTALERT>No hay publicaciones</TEXTALERT>
        ) : (
          <div className="wrapper-card-publications mb-3">
            {myPublicationsConcess.map((publication, index) => (
              <PublicationCard
                data={publication}
                key={index}
                setPublicationList={setMyPublicationsConcess}
                carousel={false}
                disabledStatusButton={false}
                editButton={false}
                style={{ animationDelay: `${(index + 1) * 0.065}s` }}
              />
            ))}
          </div>
        )}
        <Pagination
          publicationPerPage={publicationPerPage}
          totalPublication={totalPublication}
          paginate={handlePagination}
          currentPage={currentPage}
        />
      </div>
      <ConcessionaireList />
      <FooterPage />
    </>
  );
}
