import { Form, Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import swal from "sweetalert";

import { EnvelopeIcon } from "../utils/styled-components/icons";
import { postEmail } from "../services/publication";

export default function ModalContact(props) {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    emailFrom: "",
    emailTo: props.emailTo,
    phone: "",
    message:
      "Hola, me interesa el vehiculo publicado en la web AUTOSMISIONES, dejo informacion de contacto. Saludos!",
    publicationImg: props.publicationImg,
  });
  let publicationUrl = props.urlPublication;

  const handleSendEmail = async () => {
    await postEmail(publicationUrl, formData);
    setShowModal(!showModal);
    displaySwall();
  };

  const displaySwall = async () => {
    swal({
      title: "Enviado",
      text: "La consulta ha sido enviada con exito",
      icon: "success",
      button: "Aceptar",
    });
  };

  const onChangeInput = (event) => {
    let val;
    if (event.target.type === "text") {
      val = event.target.value.replace(/\./g, "");
    } else {
      val = event.target.value;
    }
    setFormData((formData) => {
      return { ...formData, [event.target.name]: val };
    });
  };

  return (
    <>
      <button className="btn-social --email" onClick={() => setShowModal(true)}>
        <EnvelopeIcon style={{ fontSize: "16px" }} />
      </button>
      <Modal
        size="sm"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="text-center"
            id="example-modal-sizes-title-sm"
          >
            Deja tu Consulta
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Form className="px-2">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Mi nombre"
                onChange={onChangeInput}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput2">
              <Form.Label>Email de contacto</Form.Label>
              <Form.Control
                type="email"
                name="emailFrom"
                placeholder="usuario@ejemplo.com"
                required
                onChange={onChangeInput}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput3">
              <Form.Label>Telefono de contacto</Form.Label>
              <Form.Control
                name="phone"
                type="number"
                placeholder="543764123456"
                onChange={onChangeInput}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput3">
              <Form.Label>Email del vendedor</Form.Label>
              <Form.Control
                name="phone"
                type="number"
                placeholder={formData.emailTo}
                readOnly
                disabled
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Consulta</Form.Label>
              <Form.Control
                name="message"
                as="textarea"
                rows={6}
                defaultValue={formData.message}
                onChange={onChangeInput}
              />
            </Form.Group>
            <div className="w-100 text-right">
              <Button onClick={() => handleSendEmail()}>Enviar</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
