import requests from "../utils/requests";
import { getLocalStorage } from "../utils/localStorage";

export const getAllPerson = async () => {
  const filter = `filter={"include":"roles"}`;
  const url = `${process.env.REACT_APP_API_URL}/api/people/?${filter}`;
  return await requests(url, "GET");
};

export const getBussiness = async () => {
  const url = `${process.env.REACT_APP_API_URL}/api/people/bussiness`;
  return await requests(url, "GET");
};

export const getRolesUser = async () => {
  const url = `${process.env.REACT_APP_API_URL}/api/Roles`;
  return await requests(url, "GET");
};

export const signIn = async ({ email, password }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/people/login`;
  return await requests(url, "POST", { email, password });
};

export const signUp = async ({ firstName, lastName, email, password }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/people`;
  return await requests(url, "POST", { firstName, lastName, email, password });
};

export const resetPassword = async ({ password, access_token }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/people/reset-password?access_token=${access_token}`;
  return await requests(url, "POST", { newPassword: password });
};

// Send email to recovery password
export const forgetPassword = async ({ email }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/people/reset`;
  return await requests(url, "POST", { email });
};

export const getPerson = async ({ id }) => {
  const filter = `filter={"include": ["phones", "roles","personMediaResources"]}`;
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/people/${id}?${filter}&access_token=${getLocalStorage("token")}`;
  return await requests(url, "GET");
};

export const getRolePerson = async ({ id }) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/people/${id}/roles?access_token=${getLocalStorage("token")}`;
  return await requests(url, "GET");
};

export const getUserImages = async ({ personId }) => {
  console.log("async" + personId);
  const filter = `filter[where][personId]=${personId}`;
  const url = `${process.env.REACT_APP_API_URL}/api/personMediaResources?${filter}`;
  return await requests(url, "GET");
};

export const updatePerson = async (data) => {
  const { phones, firstName, lastName, openingHours, locationStreet } = data;
  const personId = getLocalStorage("userId");

  if (!personId) return;

  const phoneAlreadyExist = Boolean(phones[0]?.id);
  if (phoneAlreadyExist) {
    await updatePhone({
      id: personId,
      fk: phones[0].id,
      phone: phones[0].phone,
    });
  } else {
    await createPhone({ id: personId, phone: phones[0].phone });
  }

  const url = `${
    process.env.REACT_APP_API_URL
  }/api/people/${personId}?access_token=${getLocalStorage("token")}`;

  return await requests(url, "PATCH", {
    firstName,
    lastName,
    openingHours,
    locationStreet,
  });
};

export const updateRolePerson = async ({ userId, roleId, userRoleId }) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/people/${userId}/roles/${userRoleId}?access_token=${getLocalStorage(
    "token"
  )}`;

  return await requests(url, "PUT", { roleId });
};

export const updatePhone = async ({ id, fk, phone }) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/people/${id}/phones/${fk}?&access_token=${getLocalStorage("token")}`;

  return await requests(url, "PUT", { phone });
};

export const createPhone = async ({ id, phone }) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/people/${id}/phones?access_token=${getLocalStorage("token")}`;

  return await requests(url, "POST", { phone });
};

export const postPersonMultimedia = async (personId, data) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/personMediaResources/file-upload?personId=${personId}&access_token=${getLocalStorage(
    "token"
  )}`;
  return await requests(url, "POST", data);
};
