import React, { useState, useRef, useEffect } from "react";
import { InputGroup, Form, Row, Col, Card, Button } from "react-bootstrap";
import swal from "sweetalert";

import { getPerson, updatePerson } from "../../services/userAccount";
import { getLocalStorage } from "../../utils/localStorage";
import {
  EnvelopeIcon,
  CheckIcon,
  EditIcon,
  SaveIcon,
  UserIcon,
  PhoneIcon,
  CancelIcon,
  ClockIcon,
  DirectionsIcon,
} from "../../utils/styled-components/icons";
import { TEXTFLAG, TEXTPHONE } from "../../components/Dashboard/styles";

export default function MyProfile() {
  const [editBtn, setEditBtn] = useState(false);
  const [prevData, setPrevData] = useState();
  const editButton = useRef(null);
  const [userData, setUserData] = useState(false);

  useEffect(() => {
    getUserData();
    editButton.current.focus();
  }, []);

  const getUserData = async () => {
    const userId = getLocalStorage("userId");
    const uData = await getPerson({ id: userId });
    setUserData(uData.data);
    setPrevData(JSON.stringify(uData.data));
  };

  async function handleEdit() {
    try {
      const response = await updatePerson(userData);
      if (response.error) {
        swal({
          title: "Error",
          text: response.error,
          icon: "error",
          button: "Ok",
        });
      } else {
        showSwall(response, "Datos actualizados correctamente", "success");
      }
    } catch (error) {
      swal({
        title: "Error",
        text: error,
        icon: "error",
        button: "Ok",
      });
    } finally {
      setEditBtn(false);
    }
  }

  const onChangeInput = (event) => {
    const {
      value,
      name,
      dataset: { index },
    } = event.target;
    if (name === "phones") {
      // If phone doesn't exist, create it
      if (!index) {
        userData.phones.push({ phone: value });
        setUserData((userData) => {
          return { ...userData };
        });
      } else {
        const phones = userData.phones;
        phones[index].phone = value;
        setUserData({ ...userData, phones });
      }
    } else {
      setUserData((userData) => {
        return { ...userData, [event.target.name]: value };
      });
    }
  };

  const handleCancel = (data) => {
    setUserData(JSON.parse(prevData));
    setEditBtn(false);
  };

  async function showSwall(response, successTitle, iconResponse) {
    if (!response.error) {
      swal({
        title: successTitle,
        icon: iconResponse,
        button: "Ok",
      });
    }
  }

  return (
    <Card className="shadow-lg mb-5 bg-white rounded">
      <Card.Body className="p-3">
        <Card.Title>
          Mi Perfil
          {editBtn && (
            <Button
              onClick={() => handleCancel(userData)}
              variant="outline-danger"
              className="btn btn-sm float-right m-1"
              title="Cancelar"
            >
              <CancelIcon title="Cancelar" />
            </Button>
          )}
          <Button
            onClick={() => {
              editBtn ? handleEdit() : setEditBtn(true);
            }}
            ref={editButton}
            variant={editBtn ? "outline-success" : "outline-primary "}
            className="btn btn-sm float-right m-1"
            title={
              editBtn ? "Guardar mis cambios" : "Actualizar mi información"
            }
          >
            {editBtn ? (
              <SaveIcon title="Guardar mis cambios" />
            ) : (
              <EditIcon title="Actualizar mi información" />
            )}
          </Button>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {userData?.lastName} {userData?.firstName}
        </Card.Subtitle>
        <form>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <EnvelopeIcon title="Email" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              id="email"
              name="email"
              type="text"
              readOnly
              defaultValue={userData?.email}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <CheckIcon
                  title={
                    userData?.emailVerified
                      ? "Email verificado"
                      : "Email no verificado"
                  }
                  color={userData?.emailVerified ? "green" : ""}
                />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <UserIcon title="Nombre" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              id="firstName"
              name="firstName"
              type="text"
              readOnly={!editBtn}
              defaultValue={userData?.firstName}
              onChange={onChangeInput}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <UserIcon title="Apellido" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              id="lastName"
              name="lastName"
              type="text"
              readOnly={!editBtn}
              value={userData?.lastName}
              onChange={onChangeInput}
            />
          </InputGroup>

          {userData?.roles?.length && userData.roles[0].roleId === 1 && (
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <DirectionsIcon title="Direccion" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                placeholder="Dirección"
                id="locationStreet"
                name="locationStreet"
                type="text"
                readOnly={!editBtn}
                value={userData?.locationStreet}
                onChange={onChangeInput}
              />
            </InputGroup>
          )}

          {userData?.roles?.length && userData.roles[0].roleId === 1 && (
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <ClockIcon title="Horarios" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                placeholder="Horario de atención"
                id="openingHours"
                name="openingHours"
                type="text"
                readOnly={!editBtn}
                value={userData?.openingHours}
                onChange={onChangeInput}
              />
            </InputGroup>
          )}

          {userData?.phones &&
            userData?.phones.map((p, index) => (
              <Col>
                <Row>
                  <InputGroup key={index} className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <PhoneIcon title="Teléfono" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      prefix={"+549"}
                      data-index={index}
                      name="phones"
                      type="text"
                      readOnly={!editBtn}
                      value={p?.phone}
                      onChange={onChangeInput}
                    />
                  </InputGroup>
                </Row>

                <Row className="justify-content-center m-1">
                  <TEXTPHONE>(cod. pais + cod. área + teléfono)</TEXTPHONE>
                </Row>

                <Row className="justify-content-center m-1">
                  ej:
                  <img
                    className="img-fluid m-1"
                    src="img/argentina.svg"
                    width="20"
                    height="20"
                    alt="ARG-Flag"
                  />
                  <TEXTFLAG>549 3764 123456</TEXTFLAG>
                </Row>

                <Row className="justify-content-center m-1">
                  ej:
                  <img
                    className="img-fluid m-1"
                    src="img/paraguay.svg"
                    width="20"
                    height="20"
                    alt="PY-Flag"
                  />
                  <TEXTFLAG>595 975 123456</TEXTFLAG>
                </Row>
              </Col>
            ))}

          {!userData?.phones?.length && (
            <Col>
              <Row>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <PhoneIcon title="Teléfono" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    prefix={"+549"}
                    name="phones"
                    type="text"
                    readOnly={!editBtn}
                    onChange={onChangeInput}
                  />
                </InputGroup>
              </Row>
            </Col>
          )}
        </form>
      </Card.Body>
    </Card>
  );
}
