import React from "react";
import MainLayout from "../layouts/MainLayout";

export default function HomePage() {
  return (
    <>
      <MainLayout></MainLayout>
    </>
  );
}
