import React from "react";

export default function AdvertisingHome({ ad = 0 }) {
  const hashAd = {
    0: {
      img: "img/logoMusicRadio.png",
      customClass: "img-fluid",
      flag: 2,
      url: "https://music915.com.ar",
      },
    1: {
      img: "img/naranjosJpeg.jpg",
      customClass: "img-fluid",
      flag: 2,
      url: "https://wa.me/5493765050405",
    },
    2: {
      img: "img/logoMusicRadio.png",
      customClass: "img-fluid",
      flag: 2,
      url: "https://music915.com.ar",
    },
    3: {
      img: "img/naranjosJpeg.jpg",
      customClass: "img-fluid",
      flag: 2,
      url: "https://wa.me/5493765050405",
    },
  };

  return (
    <div className="ads-cards" horizontal={`horizontal`}>
      <div className="ads-cards__card-ad">
        <a
          href={hashAd[ad]?.url}
          className="btn w-100 rounded-0 rounded-bot position-relative"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={hashAd[ad]?.img}
            alt="imagen"
            className={hashAd[ad]?.customClass}
          />
          {hashAd[ad]?.flag === 1 && (
            <span className="textSecHomeTwo mb-4">
              <p
                className="mb-0 fw-800"
                style={{
                  textShadow:
                    "0px 1px 0px #000, 0px -1px 0px #000, -1px 0px 0px #000, 1px 0px 0px #000",
                }}
              >
                Publica aquí
              </p>
            </span>
          )}
        </a>
      </div>
    </div>
  );
}
