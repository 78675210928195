import React from "react";
import { Form, Col, Button, Container, Nav } from "react-bootstrap";
import NumberFormat from "react-number-format";

export default function PrincipalTab({
  nextTab,
  onChangeInput,
  onChangeOptionCategory,
  onChangeOptionMake,
  onChangeOptionModel,
  onChangeOptionVersion,
  categories,
  makes,
  models,
  versions,
  customMake,
  customModel,
  customVersion,
  formData,
  vehicleCategorySelectRef,
  vehicleMakeSelectRef,
  vehicleModelSelectRef,
  vehicleVersionSelectRef,
}) {
  const showOtherFieldMake = () => {
    return (
      customMake !== null ||
      formData?.vehicleMakeId === "otro" ||
      !makes?.length
    );
  };
  const showOtherFieldModel = () => {
    return (
      customMake !== null ||
      customModel !== null ||
      formData?.vehicleMakeId === "otro" ||
      formData?.vehicleModelId === "otro" ||
      !models?.length
    );
  };
  const showOtherFieldVersion = () => {
    return (
      customMake !== null ||
      customModel !== null ||
      customVersion !== null ||
      formData?.vehicleMakeId === "otro" ||
      formData?.vehicleModelId === "otro" ||
      formData?.vehiculoVersionId === "otro" ||
      !versions?.length
    );
  };

  return (
    <Container>
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Control
              id="title"
              type="text"
              name="title"
              placeholder="Titulo de la publicacion"
              onChange={(e) => onChangeInput(e)}
              value={formData.title}
              required
            />
            <Form.Text>
              También puedes incluir: marca, modelo, año y version.
            </Form.Text>
          </Form.Group>
        </Col>
      </Form.Row>
      <br />
      <Form.Row>
        <Col>
          <Form.Label className="mx-2">Condición</Form.Label>
          <Form.Check
            inline
            custom
            label="Usado"
            name="condition"
            type="radio"
            value="Usado"
            checked={
              formData?.condition === "Usado" || formData?.condition === ""
                ? true
                : ""
            }
            required
            id="custom-inline-1"
            onChange={(e) => onChangeInput(e)}
          ></Form.Check>
          <Form.Check
            inline
            custom
            label="Nuevo"
            name="condition"
            type="radio"
            value="Nuevo"
            checked={
              formData?.condition !== "" && formData?.condition === "Nuevo"
                ? true
                : ""
            }
            required
            id="custom-inline-2"
            onChange={(e) => onChangeInput(e)}
          />
        </Col>
      </Form.Row>
      <br />
      <Form.Row>
        <Col sm="12" md="6">
          <Form.Group>
            <Form.Control
              as="select"
              ref={vehicleCategorySelectRef}
              onChange={onChangeOptionCategory(this)}
              name="vehicleCategoryId"
            >
              <option selected={formData?.vehicleCategoryId === ""} disabled>
                Categoria
              </option>
              {categories.map((category) => (
                <option
                  key={category.id}
                  value={category.id}
                  selected={
                    formData?.vehicleCategoryId.toString() ===
                    category.id?.toString()
                  }
                >
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col sm="12" md="6">
          <Form.Group>
            <Form.Control
              name="vehicleMakeId"
              as="select"
              ref={vehicleMakeSelectRef}
              onChange={onChangeOptionMake(this)}
              disabled={!makes?.length}
            >
              <option selected={formData?.vehicleMakeId === ""} disabled>
                Marca
              </option>

              {makes.map((make) => (
                <option
                  key={make.id}
                  value={make.id}
                  selected={
                    formData?.vehicleMakeId?.toString() === make.id?.toString()
                  }
                >
                  {make.name}
                </option>
              ))}
              <option value="otro">Otro</option>
            </Form.Control>
            {showOtherFieldMake() && (
              <Form.Control
                name="vehicleCustomMake"
                type="text"
                placeholder="Marca"
                value={formData.vehicleCustomMake}
                onChange={(e) => onChangeInput(e)}
              />
            )}
          </Form.Group>
        </Col>

        <Col sm="12" md="6">
          <Form.Group>
            <Form.Control
              name="vehicleModelId"
              as="select"
              onChange={onChangeOptionModel(this)}
              disabled={showOtherFieldMake()}
              ref={vehicleModelSelectRef}
            >
              <option selected={formData?.vehicleModelId === ""} disabled>
                Modelo
              </option>
              {models.map((model) => (
                <option
                  key={model.id}
                  value={model.id}
                  selected={
                    formData?.vehicleModelId?.toString() ===
                    model.id?.toString()
                  }
                >
                  {model.name}
                </option>
              ))}
              <option value="otro">Otro</option>
            </Form.Control>
            {showOtherFieldModel() && (
              <Form.Control
                name="vehicleCustomModel"
                type="text"
                placeholder="Modelo"
                value={formData?.vehicleCustomModel}
                onChange={(e) => onChangeInput(e)}
              />
            )}
          </Form.Group>
        </Col>

        <Col sm="12" md="6">
          <Form.Group>
            <Form.Control
              as="select"
              name="vehiculoVersionId"
              ref={vehicleVersionSelectRef}
              onChange={onChangeOptionVersion(this)}
              disabled={
                showOtherFieldMake() ||
                showOtherFieldModel() ||
                !versions?.length
              }
            >
              <option selected={formData?.vehiculoVersionId === ""} disabled>
                Version
              </option>
              {versions.map((version) => (
                <option
                  key={version.id}
                  value={version.id}
                  selected={
                    formData.vehiculoVersionId?.toString() ===
                    version.id?.toString()
                  }
                >
                  {version.name}
                </option>
              ))}
              <option value="otro">Otro</option>
            </Form.Control>
            {showOtherFieldVersion() && (
              <Form.Control
                name="vehicleCustomVersion"
                type="text"
                placeholder="Version"
                value={formData?.vehicleCustomVersion}
                onChange={(e) => onChangeInput(e)}
              />
            )}
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col sm="12" md="6">
          <Form.Group>
            <Form.Control
              as="textarea"
              name="description"
              id="description"
              rows={6}
              placeholder="Descripción"
              onChange={(e) => onChangeInput(e)}
              value={formData.description}
            />
          </Form.Group>
        </Col>

        <Col sm="12" md="4" className="align-items-center m-1">
          <NumberFormat
            placeholder={
              formData.vehicleCategoryId === "3" ? "Horas" : "kilometros"
            }
            value={formData?.km}
            decimalSeparator={","}
            thousandSeparator={"."}
            onValueChange={(v) => {
              const { value } = v;
              onChangeInput({ target: { value: value, name: "km" } });
            }}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <br />
        <Col md="4" sm="12" className="align-items-center m-1">
          <NumberFormat
            placeholder="Año/Modelo"
            value={formData?.year}
            decimalSeparator={","}
            thousandSeparator={"."}
            onValueChange={(v) => {
              const { value } = v;
              onChangeInput({ target: { value: value, name: "year" } });
            }}
          />
        </Col>
        <br />
        <Col md="2" sm="12">
          <Form.Group className="align-items-center">
            <Form.Check
              custom
              name="uniqueOwner"
              label="Unico dueño"
              type="checkbox"
              checked={formData?.uniqueOwner ? true : false}
              id="custom-inline-3"
              onChange={(e) => onChangeInput(e)}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <br />
      <Form.Row>
        <Col>
          <Button onClick={nextTab} type="button" className="float-right">
            Siguiente
          </Button>
          <Nav.Link className="float-right mx-2" href="/">
            Volver al inicio
          </Nav.Link>
        </Col>
      </Form.Row>
    </Container>
  );
}
