import React, { useState } from "react";

import { getLocalStorage } from "../utils/localStorage";
import { ROLES } from "../utils/types.d";

const Context = React.createContext({});

export function UserContextProvider({ children }) {
  const [jwt, setJWT] = useState(() => getLocalStorage("token"));
  const [user, setUser] = useState(() => {
    const userFromLocalStorage = getLocalStorage("user");
    return userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;
  });

  const isAdmin = () => {
    const roleIds = user?.roles?.map((role) => role?.roleId);
    return roleIds?.includes(ROLES.ADMIN);
  };

  const isBussiness = () => {
    const roleIds = user?.roles?.map((role) => role?.roleId);
    return roleIds.includes(ROLES.BUSSINESS);
  };

  return (
    <Context.Provider
      value={{ jwt, setJWT, user, setUser, isAdmin, isBussiness }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
